// ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const reg_type = useSelector((state) => state.auth.reg_type);

  if (reg_type !== 4 && reg_type !== 5) {
    // Redirect to the authentication page if the user does not have access
    return <Navigate to="/" replace />;
  }

  return children; // Render the protected route if the user has access
};

export default ProtectedRoute;
