import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'; // Import useSelector to access the Redux store

const AddSubCourses = () => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [formData, setFormData] = useState({
    sub_course_name: '',
    created_by: 1,
    course_master_id: null
  });
  const { token } = useSelector((state) => state.auth); // Get the token from the Redux store

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-values-array/course_master`);
        const courseOptions = response.data.map((course) => ({
          value: course[0],
          label: course[1],
        }));
        setCourses(courseOptions);
      } catch (error) {
        toast.error('Failed to fetch courses');
        console.error('Failed to fetch courses:', error);
      }
    };

    fetchCourses();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart()
    }));
  };

  const handleCourseChange = (selectedOption) => {
    setFormData({ ...formData, course_master_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Trim all string fields in formData
      const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
        acc[key] = typeof formData[key] === 'string' ? formData[key].trim() : formData[key];
        return acc;
    }, {});

    const newSubCourse = { ...trimmedFormData };
      await axios.post(`${Base_URL}/create/sub_courses_master`, newSubCourse, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}` // Add authorization header with token
        },
      });
      toast.success('Sub Course added successfully!');
    } catch (error) {
      toast.error('Failed to add sub course.');
      console.error('Failed to add sub course:', error.response?.data || error.message);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />

        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="sub_course_name" className="mb-2 font-medium text-sm">Add Sub Course</label>
                <input
                  type="text"
                  id="sub_course_name"
                  name="sub_course_name"
                  value={formData.sub_course_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-medium text-sm">Select Course</label>
                <Select
                  options={courses}
                  value={courses.find(course => course.value === formData.course_master_id)}
                  onChange={handleCourseChange}
                  placeholder="Select Course"
                  className="basic-single"
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">Add Sub Course</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSubCourses;
