import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import { useSelector } from "react-redux";

const CareerBranchContent = () => {
  const { title } = useParams();
  const [matchedCourse, setMatchedCourse] = useState(null);
  const [programTitle, setProgramTitle] = useState("Program");
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const decodedTitle = title.replace(/-/g, " ");
        console.log("Decoded Title:", decodedTitle);

        const response = await axios.get(`${Base_URL}/branch-content`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("API Data:", response.data);
        const data = response.data || [];

        const foundCourse = data.find(
          (course) => course.branch_title.trim().toLowerCase() === decodedTitle.trim().toLowerCase()
        );

        if (foundCourse) {
          setMatchedCourse(foundCourse);
          setProgramTitle(foundCourse.title);
        } else {
          setMatchedCourse(null);
          setProgramTitle("Program");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (title) {
      fetchCourses();
    }
  }, [title, token]);

  const handleNavigate = (title) => {
    const formattedTitle = title.trim().replace(/\s+/g, "-").toLowerCase();
    navigate(`/career/subcontent/${formattedTitle}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow p-6 mt-20 ">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
           Explore Branch Content for {title}
          </h2>
          <div className="grid grid-cols-2  mt-20 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {matchedCourse ? (
              <div
                key={matchedCourse.id}
                className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
              >
                <button
                  className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                  onClick={() => handleNavigate(matchedCourse.title)} // Changed to use title instead of branch_title
                >
                  {matchedCourse.title}
                </button>
              </div>
            ) : (
              <p className="col-span-4 text-center">No courses found for this program.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBranchContent;
