import React from "react";
import {ReactComponent as Logo } from '../../../../assets/landing/Logo_Green.svg'
import { ReactComponent as User } from "../../../../assets/header/User.svg";
import { Link } from "react-router-dom";

const PartnerNavbar = ({ username }) => {
  return (
    <div className="flex items-center h-[100px] justify-between border-b  shadow-md px-6 py-4" 
    style={{
      boxShadow: "1px 2px 11px 4px #00000040",
       // Adjust navbar background color
    }}>
      {/* Logo */}
      <div className="text-lg font-bold">
      <Link to="/"> {/* Link to the home page */}
          <Logo />
        </Link>
        </div>
      {/* Center Title - Hidden on mobile */}
      <div className="text-lg font-bold text-textGray hidden lg:block">
        Certified Partner Dashboard
      </div>
     
      {/* Username and User Icon */}
      <div className="flex items-center justify-center space-x-2 bg-white md:border md:border-cmsgreen rounded-full px-4 py-2 shadow-md cursor-pointer">
        {/* User icon always visible */}
        <User className="h-5 w-5 text-textGray" />
        {/* Username visible only on larger screens */}
        <span className="hidden sm:block text-textGray text-xs">
          Hi {username?.split(" ")[0]}
        </span>
      </div>
    </div>
  );
};

export default PartnerNavbar;
