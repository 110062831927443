import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast,ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../apiConfig";

const ExpertHelp = ({ isOpen, onClose,course_id,program_id,university_id }) => {
  const modalRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    mobilenumber: "",
    description: "",
    email:null,
    course_master_id:course_id ?`${course_id}`:null,
    program_master_id:course_id?null:program_id?`${program_id}`:null,
    university_id:university_id?`${university_id}`: null

  });
  const userData = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    console.log('token',program_id)

    if (userData?.registration_id) {
      setFormData((prevData) => ({
        ...prevData,
        name: userData.name || "",
        mobilenumber: userData.mobilenumber || "",
      }));
    }
  }, [userData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const resetForm = () => {
    setFormData({
      name: userData?.registration_id ? userData.name : "",
      mobilenumber: userData?.registration_id ? userData.mobilenumber : "",
      description: "",
    });
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const mobileRegex = /^\d{10}$/;
    if (!mobileRegex.test(formData.mobilenumber)) {
      toast.error("Please enter a valid 10-digit contact number.");
      return;
    }
  
    const payload = {
      ...formData,
    };
  
    
   

    const authPayload={
      name: formData.name,
      description:formData.description,
      mobilenumber: formData.contact_no,
      university_id: formData.university_id,
      program_master_id: course_id ? null : program_id?`${program_id}`:null,
      course_master_id: course_id ? `${course_id}` : null,
    }

    if(token){




      try {
        await axios.post(`${Base_URL}/apply-now`, authPayload, {
         headers: { Authorization: `Bearer ${token}` },
       
         });
         toast.success("Application submitted successfully!");
  
       setTimeout(()=>{
        onClose();
        resetForm();
       },1000)  
       
       } catch (error) {
         if (error.response && error.response.data && error.response.data.message) {
           toast.error(error.response.data.message);
         } else {
           toast.error("Submission failed! Please try again.");
         }
       }

    }
else{
    try {
     await axios.post(`${Base_URL}/expert-help`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    
      });
      toast.success("Application submitted successfully!");
      resetForm();
      onClose();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Submission failed! Please try again.");
      }
    }
  };

  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 !z-[999] flex items-center justify-center bg-black bg-opacity-50"style={{zIndex:999}} >
      <div
        ref={modalRef}
        className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md relative"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-gray-800">Expert Help</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              required
              disabled={userData?.registration_id}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Contact Number
            </label>
            <input
              type="tel"
              name="mobilenumber"
              value={formData.mobilenumber}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              required
              disabled={userData?.registration_id}
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-cmsgreen text-white py-2 px-4 rounded-md hover:bg-green-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </form>
      </div>
      <ToastContainer/>
    </div>
  );
};

export default ExpertHelp;
