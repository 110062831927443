import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Educational_Loan from '../assets/StudentDash/Educational_Loan.webp';
import Refer_a_Friend from '../assets/StudentDash/Refer_a_Friend.webp';
import Schedule_a_call from '../assets/StudentDash/Schedule_a_call.webp';
import whatsapp from '../assets/StudentDash/whatsapp.webp';
import ReferEarnModal from '../components/Header/Modal/ReferEarnModal';
import MobileReferEarn from '../components/Header/MobileReferEarn';


const StudentDashboard = ({ onCompleteProfile }) => {
  const [isReferEarnModalOpen, setReferEarnModalOpen] = useState(false);
  const auth = useSelector((state) => state.auth);
  const userName = auth?.name || 'Null';
  const navigate = useNavigate();

  // Content array for the cards
  const cardContent = [
    {
      heading: 'Education Loan',
      paragraph: 'Explore flexible education loan options tailored to your needs. Check your eligibility and apply for financial assistance seamlessly.',
      footer: 'Explore Loans',
      image: Educational_Loan,
    },
    {
      heading: 'Schedule a Call',
      paragraph: 'Need personalized guidance? Schedule a call with our experts to help you make the right decisions for your academic journey.',
      footer: 'Call Us',
      actionType: 'call',
      actionValue: '7889761896',
      image: Refer_a_Friend,
    },
    {
      heading: 'Refer and Earn',
      paragraph: 'Invite friends to join and earn rewards! Spread the word about our platform and get exclusive benefits for every successful referral.',
      footer: 'Start Referring',
      actionType: 'refer',
      actionValue: null,
      image: Schedule_a_call,
    },
    {
      heading: 'Connect on Whatsapp',
      paragraph: 'Have questions or need instant support? Connect with our team on WhatsApp for quick assistance anytime, anywhere.',
      footer: 'Chat Now',
      actionType: 'whatsapp',
      actionValue: '7889761896',
      image: whatsapp,
    },
  ];

  const handleFooterClick = (actionType, actionValue) => {
    if (actionType === 'call') {
      window.location.href = `tel:${actionValue}`; // Open phone dialer
    } else if (actionType === 'whatsapp') {
      window.open(`https://wa.me/${actionValue}`, '_blank'); // Open WhatsApp chat
    } else if (actionType === 'refer') {
      openReferEarnModal(); // Open the Refer & Earn modal
    }
  };

  const openReferEarnModal = () => {
    setReferEarnModalOpen(true);
  };

  const closeReferEarnModal = () => {
    setReferEarnModalOpen(false);
  };

  return (
    <div className="flex flex-col space-y-4">
      {/* Top Section */}
      <div className="w-full h-auto bg-white border border-black/25 shadow-md rounded-md flex flex-col sm:flex-row items-center justify-between p-4 gap-4">
        <h2 className="text-center sm:text-left text-base sm:text-lg md:text-xl font-semibold text-black">
          Hey {userName}, Your Profile is Incomplete!
        </h2>
        <button className="w-full sm:w-auto bg-[#459A07] text-white px-4 py-2 rounded-md font-medium hover:opacity-90" onClick={onCompleteProfile}>
          Complete Your Profile
        </button>
      </div>

      {/* Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 w-full">
        {cardContent.map((card, index) => (
          <div key={index} className="w-full bg-white border border-black/25 shadow-md rounded-md flex flex-col sm:flex-row gap-4 p-4">
            {/* Image Section */}
            <div className="w-full sm:w-[250px] h-[150px] bg-gray-300 rounded-md flex-shrink-0 overflow-hidden" aria-hidden="true">
              <img src={card.image} alt="No Image" className="w-full h-full object-cover" />
            </div>

            {/* Content Section */}
            <div className="flex flex-col justify-between flex-grow">
              <h3 className="text-lg font-semibold">{card.heading}</h3>
              <p className="text-sm text-gray-600 leading-relaxed">{card.paragraph}</p>
              <button className="text-xs font-medium text-[#3ACA2E] text-left" onClick={() => handleFooterClick(card.actionType, card.actionValue)}>
                {card.footer}
              </button>

              {/* Render Modal/Component Based on Screen Size */}
              {card.actionType === 'refer' && (
  <>
    {/* For Mobile View */}
    {isReferEarnModalOpen && (
      <div className="sm:hidden mt-5">
        <MobileReferEarn isOpen={isReferEarnModalOpen} onRequestClose={closeReferEarnModal} />
      </div>
    )}

    {/* For Larger Screens */}
    {isReferEarnModalOpen && (
      <div className="hidden sm:block">
        <ReferEarnModal isOpen={isReferEarnModalOpen} onRequestClose={closeReferEarnModal} />
      </div>
    )}
  </>
)}

            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentDashboard;
