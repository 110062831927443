import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../../apiConfig";


const ViewStudents = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { registration_id, token, name } = useSelector((state) => state.auth);


  // Sample data
  // const students = [
  //   {
  //     name: "John Doe",
  //     contact: "1234567890",
  //     university: "Oxford University",
  //     status: "Pending",
  //   },
  //   {
  //     name: "Jane Smith",
  //     contact: "9876543210",
  //     university: "Harvard University",
  //     status: "In Progress",
  //   },
  //   {
  //     name: "Alice Johnson",
  //     contact: "1231231234",
  //     university: "Stanford University",
  //     status: "Completed",
  //   },
  //   {
  //     name: "Robert Brown",
  //     contact: "4564564567",
  //     university: "MIT",
  //     status: "Pending",
  //   },
  // ];

  // Function to fetch student data
  const fetchStudents = async () => {
    try {
      const response = await axios.get(`${Base_URL}/certified-partner/referred-students`,
         {
          headers: {
            Authorization: `Bearer ${token}`, // Sending token in headers
          },
        }
      );
      setStudents(response.data);  // Assuming response.data is an array of students
    } catch (error) {
      console.error("Error fetching student data:", error);
      setError("Failed to load students.");
    } finally {
      setLoading(false);
    }
  };

  // Use useEffect to call fetchStudents when the component mounts
  useEffect(() => {
    fetchStudents();
  }, []);

  // Function to dynamically set the status background color
  const getStatusClass = (status) => {
    switch (status) {
      case "Pending":
        return "bg-yellow-200 text-yellow-800";
      case "In Progress":
        return "bg-purple-200 text-purple-800";
      case "Completed":
        return "bg-green-200 text-green-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  return (
    <div className="p-6 rounded-lg">
       <div className="overflow-x-auto">
      <table className="w-full border-collapse border border-slate-200">
        <thead>
          <tr className="bg-[#DBFDC3] text-slate-500 rounded-md">
            <th className="py-3 px-4 text-left  border border-slate-200 ">S.No</th>
            <th className="py-3 px-4 text-left border border-slate-200 ">Student Name</th>
            <th className="py-3 px-4 text-left border border-slate-200 ">Contact Number</th>
            <th className="py-3 px-4 text-left border border-slate-200 ">University Applied</th>
            <th className="py-3 px-4 text-left border border-slate-200 ">Added On</th>
            <th className="py-3 px-4 text-left border border-slate-200 ">Status</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student, index) => (
            <tr
              key={index}
              className={`border border-slate-200 ${
                index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
              }`}
            >
              {/* Serial number column */}
              <td className="py-3 px-4 text-slate-600 border border-slate-200">{index + 1}</td>
              <td className="py-3 px-4 text-slate-600 border border-slate-200">{student.name}</td>
              <td className="py-3 px-4 text-slate-600 border border-slate-200">{student.mobilenumber}</td>
              <td className="py-3 px-4 text-slate-600 border border-slate-200">{student.university}</td>
              <td className="py-3 px-4 text-slate-600 border border-slate-200">{student.created_on.split('T')[0]}</td>
              <td className="py-3 px-4">
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusClass(
                    student.admission_status
                  )}`}
                >
                  {student.admission_status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default ViewStudents;
