import React, { useEffect, useRef, useState } from "react";
import throttle from 'lodash-es/throttle';
import { FaBell } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ExamListService from "../api/services/ExamListService";
import ExamData from "../components/Exams/ExamData/ExamData";
import HatCircleLoader from "../components/Loader/HatCircleLOader";

const componentMap = {
  1: { name: "Overview" },
  2: { name: "Eligibility" },
  3: { name: "Application Form" },
  4: { name: "Exam Pattern" },
  5: { name: "Syllabus" },
  6: { name: "How To Prepare" },
  7: { name: "Previous Year Papers" },
  8: { name: "Admit Card" },
  9: { name: "Result" },
  10: { name: "Counselling Process" },
};

const Exam = () => {
  const [originalTop, setOriginalTop] = useState(null); // Initially null until loaded
  const [preview, setPreview] = useState(1);
  const [examData, setExamData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const mainSectionRef = useRef(null);
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const subheaderRef = useRef(null);
  const headerHeight = 100; // Adjust this if your header height differs

  useEffect(() => {
    window.scrollTo(0, 0); // Ensure page scrolls to top on mount
  }, [location]);

  useEffect(() => {
    const updateOriginalTop = () => {
      if (subheaderRef.current) {
        // Calculate original top position relative to viewport
        setOriginalTop(
          subheaderRef.current.getBoundingClientRect().top + window.scrollY
        );
      }
    };
  
    // Update `originalTop` after a short delay to ensure DOM is fully rendered
    const delayedUpdate = setTimeout(updateOriginalTop, 200);
  
    const handleScroll = () => {
      // Make subheader sticky based on scroll position
      if (originalTop !== null && window.scrollY >= originalTop) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
  
    // Throttle scroll handler for better performance
    const throttledScrollHandler = throttle(handleScroll, 100);
  
    // Attach scroll and resize event listeners
    window.addEventListener("scroll", throttledScrollHandler);
    window.addEventListener("resize", updateOriginalTop);
  
    // Cleanup on component unmount
    return () => {
      clearTimeout(delayedUpdate);
      window.removeEventListener("scroll", throttledScrollHandler);
      window.removeEventListener("resize", updateOriginalTop);
    };
  }, [originalTop]);
  
  useEffect(() => {
    if (location.state?.selectedTab) {
      setPreview(location.state.selectedTab);
    }
    fetchExamData();
  }, [id, location.state?.selectedTab]);

  const fetchExamData = async () => {
 
    try {
      setLoading(true);

      const filters = {
        "table": "exam_master_description",
        "columns": ["exam_master_description.*","exam_master.exam_master_name"],
        "joins": [
          {
            "table": "exam_master",
            "on": "exam_master_description.exam_master_id = exam_master.id",
            "type": "INNER"
          }
        ],
        "where": {
        "exam_master.exam_master_name": id.replace(/-/g, " "),
        "exam_master_description.status": "1"
      }
      };

      const response = await ExamListService.fetchExamDetailsWithJoin(filters);

      if (!response || response.length === 0) {
        navigate("/"); // Redirect to home if no data found
        return;
      }

      setExamData(response);
    } catch (err) {
      console.error("Error fetching exam data:", err);
      setError("Failed to load exam data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePreview = (view) => {
    setPreview(view);
    mainSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const renderComponent = () => {
    if (examData.length > 0) {
      return <ExamData data={examData[0]} selectedTab={preview} />;
    }
    return null;
  };

  const getVisibleTabs = () => {
    if (!examData || examData.length === 0) return [];

    return Object.entries(componentMap).filter(([id, { name }]) => {
      const data = examData[0];
      switch (parseInt(id)) {
        case 1:
          return data.overview && data.overview.trim() !== "";
        case 2:
          return data.eligibility && data.eligibility.trim() !== "";
        case 3:
          return data.application_form && data.application_form.trim() !== "";
        case 4:
          return data.exam_pattern && data.exam_pattern.trim() !== "";
        case 5:
          return data.syllabus && data.syllabus.trim() !== "";
        case 6:
          return data.how_to_prepare && data.how_to_prepare.trim() !== "";
        case 7:
          return data.prev_year_paper && data.prev_year_paper.trim() !== "";
        case 8:
          return data.admit_card && data.admit_card.trim() !== "";
        case 9:
          return data.result && data.result.trim() !== "";
        case 10:
          return (
            data.counselling_process && data.counselling_process.trim() !== ""
          );
        default:
          return false;
      }
    });
  };

  if (loading) {
    return <HatCircleLoader />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const visibleTabs = getVisibleTabs();

  return (
    <>
  

      <div className="mx-auto mt-[40px] flex flex-col">
        {/* Top Section with Gradient Background */}
        <div
          className="flex bg-[rgba(0,0,0,0.5)] flex-col px-10 py-10"
        
        >
          <div className="flex flex-col md:flex-row justify-between items-center gap-5 px-5">
            <div>
              <p className="text-2xl text-white md:text-3xl font-semibold">
                {examData.length > 0
                  ? examData[0].exam_master_name
                  : "No Exam Data Available"}
              </p>
              <p className="text-sm text-white ">
                Updated on {new Date().toLocaleDateString()}
              </p>
            </div>
            <button className="bg-cmsgreen text-white py-2 px-4 rounded-md flex items-center gap-2">
              <FaBell />
              Set Exam Alert
            </button>
          </div>
        </div>

        {/* Sub Header with Tabs */}
        {visibleTabs.length > 0 && (
       <div
       id="subheader"
       ref={subheaderRef}
       className={`subheader top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header bg-white shadow-md md:justify-center`}
       style={{
         position: isSticky ? "fixed" : "static",
         top: isSticky ? "0px" : "auto",
         width: "100%",
         zIndex: 3,
       }}
     >
            {visibleTabs.map(([id, { name }]) => (
              <p
                key={id}
                className={`college_sub_header_option ${
                  preview === parseInt(id) ? "college_sub_header_active" : ""
                }`}
                onClick={() => handlePreview(parseInt(id))}
              >
                {name}
              </p>
            ))}
          </div>
        )}

        {/* Main Section with Data */}
        <div className="bg-gray-100" ref={mainSectionRef}>
          {renderComponent()}
        </div>
      </div>

  
    </>
  );
};

export default Exam;
