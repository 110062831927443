import React, { useState,useEffect } from 'react';
import axios from 'axios';
//import { useNavigate } from 'react-router-dom';
import { Base_URL } from '../../../apiConfig';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import Select from 'react-select';
import { useSelector } from "react-redux"; // Import useSelector


const AddAdminNotifications = () => {
  //const navigate = useNavigate();
  const [formData, setFormData] = useState({
    university_id:'',
    notification_title: '',
    notification_desc: '',
   
  });
  const [universities, setUniversities] = useState([]);
  const [loading, setLoading] = useState(false); // Track loading state
  // Retrieve token from Redux store
  const { token } = useSelector((state) => state.auth);


  useEffect(() => {
    // Fetch universities from the API to populate the react-select dropdown
    const fetchUniversities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/universities`); // Replace with your API endpoint
        const universityOptions = response.data.map(university => ({
          value: university.university_id,
          label: university.university_name
        }));
        setUniversities(universityOptions);
      } catch (error) {
        console.error('Error fetching universities:', error);
        toast.error('Failed to load universities');
      }
    };

    fetchUniversities();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setFormData(prev => ({ ...prev, university_id: selectedOption ? selectedOption.value : null }));
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting

    try {

      await axios.post(`${Base_URL}/create/admin_notifications`, formData, {
        headers: {
          'Content-Type': 'application/json',
       'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });

      toast.success('Notification added successfully!');
      // setFormData({ title: '', content: '', event_date: '', university_id: '' });
      // setImage(null);
    } catch (error) {
      console.error('Failed to add notification:', error.response?.data || error.message);
      toast.error('Failed to add notification. Please try again.');
      setLoading(false)
    } finally {
      setLoading(false); // Reset loading state after submission
    }
  };
  

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md ">
       <HeaderTitle mainTitle="Notifications" subTitle="Add Notifications" />
      <div className="border border-gray-300 bg-white p-4 rounded-md min-h-screen">
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

             {/* Select University Dropdown using react-select */}
             <div className="flex flex-col">
              <label htmlFor="university_id" className="mb-2 font-medium text-sm">Select University</label>
              <Select
                options={universities}
                value={universities.find(option => option.value === formData.university_id)}
                onChange={handleSelectChange}
                placeholder="Select a university"
                className="basic-multi-select"
                classNamePrefix="select"
                isClearable
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="title" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Notification Title</label>
              <input
                type="text"
                id="title"
                name="notification_title"
                value={formData.notification_title}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="content" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">Description</label>
              <textarea
                id="content"
                name="notification_desc"
                value={formData.notification_desc}
                onChange={handleChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="mt-4 px-4 py-2  bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen"
          >
            {loading ? ( // Show spinner when loading
              <div className="flex justify-center items-center">
                <svg
                  className="animate-spin h-5 w-5 text-white mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                  ></path>
                </svg>
                Submitting...
              </div>
            ) : (
              'Submit'
            )}
          </button>
          {/* {error && <div className="text-red-600 mt-2">{error}</div>} */}
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default AddAdminNotifications;
