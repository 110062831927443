import React from "react";
import { Outlet } from "react-router-dom";
import PartnerNavbar from "../navbar/PartnerNavbar";
import PartnerSidebar from "../sidebar/PartnerSidebar";
import { useSelector } from "react-redux";

const PartnerDashboard = () => {
  const { registration_id, token, name } = useSelector((state) => state.auth);

  return (
    <div className="min-h-screen flex flex-col">
      {/* Topbar */}
      <PartnerNavbar username={name} />

      {/* Desktop Sidebar + Main Content */}
      <div className="hidden xl:flex flex-grow">
        {/* Sidebar (20% width) */}
        <div className="w-[22%] 2xl:w-[20%]">
          <PartnerSidebar />
        </div>

        {/* Page Content (80% width) */}
        <div className="w-[78%] 2xl:[w-80%] p-6">
          <Outlet />
        </div>
      </div>

      {/* Mobile Sidebar + Main Content */}
      <div className="flex flex-col xl:hidden">
        {/* Mobile Sidebar (Horizontal Card below Navbar) */}
        <div className="fixed top-[110px] left-0 w-full bg-white shadow-lg z-10">
          <PartnerSidebar />
        </div>

        {/* Page Content (Full Width) */}
        <div className="mt-12 p-6"> {/* Adjust margin-top to push content below the sidebar */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PartnerDashboard;
