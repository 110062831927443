import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_URL } from "../../../apiConfig";
import CourseCard from "./CourseCard/CourseCard";

const Courses = ({ collegeDetails }) => {
  const [coursesData, setCoursesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  // Fetch courses data using university_id from props
  useEffect(() => {
    const fetchCoursesData = async () => {
      try {
        const response = await fetch(
          `${Base_URL}/programs/university/${collegeDetails?.university_id} `
        );
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCoursesData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    if (collegeDetails?.university_id) {
      fetchCoursesData();
    }
  }, [collegeDetails?.university_id]);

  const handleViewMore = (id, university_id) => {
    const university_name = university_id
      .trim()
      .toLowerCase()
      .replace(/\s+/g, "-");
    const program_name = id.trim().toLowerCase().replace("-"," ");
    navigate(`/coursedetail/${university_name}/${program_name}`);
  };

  if (loading) {
    return (
      <div className="flex-grow p-8 h-[80vh] flex justify-center items-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2  border-cmsgreen"></div>
      </div>
    );
  }

  if (error)
    return (
      <div className='flex-grow p-6 mt-20 bg-[#F2FCEB] flex justify-center items-center'>
        <div className='bg-red-100 text-red-700 p-6 rounded-md shadow-md max-w-lg text-center'>
          <p>{error}</p>
        </div>
      </div>
    );
  return (
    <div className=" px-5">
      <div className="py-8 flex flex-col items-center">
        <p className="text-center text-lg text-black font-semibold mb-8">
          {collegeDetails?.university_name} Courses, Fees and Eligibility
          Criteria 2025
        </p>

        {/* Responsive Grid Container */}
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 w-full'>
          {coursesData.map((course, index) => (
            <CourseCard
              key={index}
              course_count={course.course_count}
              program_master_name={course.program_master_name}
              program_fees={course.program_fees}
              program_duration={course.program_duration}
              study_mode={course.study_mode}
              eligibility={course.eligibility}
              exams_accepted={course.exams_accepted}
              id={course.id}
              university_id={collegeDetails.university_id}
              program_master_id={course.program_master_id}
              title={"View All"}
              onViewMore={() =>
                handleViewMore(
                  course.program_master_name,
                  collegeDetails.university_name
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Courses;