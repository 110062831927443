import React ,{useState,useEffect} from "react";
import { FaUserLarge } from "react-icons/fa6";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../../apiConfig";
import HatCircleLoader from "../../../../components/Loader/HatCircleLOader";


const PartnerDashboardLanding = () => {
  const { registration_id, token, name } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
   // Default stats with count 0
   const [stats, setStats] = useState([
    { title: "Total Students", value: 0, bgColor: "bg-blue-100" },
    { title: "Pending Leads", value: 0, bgColor: "bg-yellow-100" },
    { title: "In Process", value: 0, bgColor: "bg-purple-100" },
    { title: "Completed Leads", value: 0, bgColor: "bg-green-100" },
  ]);

  // Fetch data from the API
  const fetchStats = async () => {
    try {
      const response = await axios.get(`${Base_URL}/certified-partner/count`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = response.data; // Assuming the API returns the stats in a proper format
      setStats([
        { title: "Total Students", value: data.total || 0, bgColor: "bg-blue-100" },
        { title: "Pending Leads", value: data.Pending || 0, bgColor: "bg-yellow-100" },
        { title: "In Process", value: data["In Progress"] || 0, bgColor: "bg-purple-100" },
        { title: "Completed Leads",  value: data.Completed || 0, bgColor: "bg-green-100" },
      ]);
    } catch (error) {
      console.error("Error fetching stats:", error);
      setError("Failed to load dashboard stats.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  // if (loading) {
  //   return <HatCircleLoader />;
  // }

  

  

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 p-6">
      {stats.map((stat, index) => (
        <div
          key={index}
          className={`flex justify-between items-center rounded-lg shadow-lg p-6 ${stat.bgColor}`}
        >
          <div>
            <h3 className="text-md font-semibold text-gray-700">{stat.title}</h3>
            <p className="text-3xl font-bold text-gray-800">{stat.value}</p>
          </div>
          <div className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md">
            <FaUserLarge className="text-cmsgreen text-lg" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default PartnerDashboardLanding;
