import { API_ENDPOINT } from "../../utils/constant";
import apiClient from "../apiClient"; // Adjust the path based on your project structure

/**
 * Fetch filtered universities based on the selected filters.
 * @param {Array} selectedFilters Filters selected by the user.
 * @returns {Promise<Array>} List of filtered universities.
 */
const fetchFilteredUniversities = async (selectedFilters) => {
  try {
    const filtersString = JSON.stringify(selectedFilters);

    const url = `${API_ENDPOINT.FILTER_COLLEGES}?filters=${encodeURIComponent(
      filtersString
    )}`;

    const response = await apiClient.get(url);

    if (response.data && Array.isArray(response.data)) {
      const processedData= response.data.map((item) => ({
        ...item,
        pictures: JSON.parse(item.pictures), // Parse pictures if they are in JSON format
      }));

      const activeColleges = processedData.filter(
        (college) => college.is_active === 1
      );
      return activeColleges
    }

    return [];
  } catch (error) {
    console.error("Error fetching filtered universities:", error.message);
    throw new Error(
      error.response?.data?.message || "Unable to fetch universities"
    );
  }
};

/**
 * Fetch filtered exams based on the selected filters.
 * @param {Object} filterPayload Filters for the API request.
 * @returns {Promise<Array>} List of filtered exams.
 */
const fetchFilteredExams = async (filterPayload = {}) => {
  try {
    const queryString = JSON.stringify(filterPayload);
    const url = `${API_ENDPOINT.FILTER_EXAM}?filters=${encodeURIComponent(
      queryString
    )}`;

    const response = await apiClient.get(url);

    if (response.data && Array.isArray(response.data)) {
      return response.data;
    }

    return [];
  } catch (error) {
    console.error("Error fetching filtered exams:", error.message);
    throw new Error(error.response?.data?.message || "Unable to fetch exams");
  }
};

/**
 * Fetch filtered courses based on the selected filters.
 * @param {Array} selectedFilters Filters selected by the user.
 * @returns {Promise<Array>} List of filtered courses.
 */
const fetchFilteredCourses = async (selectedFilters = []) => {
  try {
    // Convert selected filters to JSON string
    const filtersString = JSON.stringify(selectedFilters);

    // Construct the API URL with query parameters
    const url = `${API_ENDPOINT.FILTER_COURSES}?filter=${encodeURIComponent(
      filtersString
    )}`;

    // Make the API request using apiClient
    const response = await apiClient.get(url);

    // Process the response data
    if (response.data && Array.isArray(response.data)) {
      return response.data;
    }

    return [];
  } catch (error) {
    console.error("Error fetching filtered courses:", error.message);
    throw new Error(error.response?.data?.message || "Unable to fetch courses");
  }
};

export { fetchFilteredUniversities, fetchFilteredExams, fetchFilteredCourses };

