import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import reportWebVitals from './reportWebVitals'
import { HelmetProvider } from "react-helmet-async";
import store, { persistor } from "./redux/store"; // Ensure store and persistor are correctly imported
import HatCircleLoader from "./components/Loader/HatCircleLOader";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Correctly initialize the QueryClient
const queryClient = new QueryClient();

root.render(

    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={<HatCircleLoader />}>
            <React.Fragment>
              <HelmetProvider>
                <Router>
                  <App />
                </Router>
              </HelmetProvider>
            </React.Fragment>
          </Suspense>
        </PersistGate>
      </Provider>
    </QueryClientProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
