import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import HeaderSection from '../../../dashboard/sidebarpages/headerSection/HeaderSection';
import { Base_URL } from '../../../apiConfig';
import { useSelector } from 'react-redux';

const CareerForm = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [manualTitle, setManualTitle] = useState('');
  const [selectedStreamId, setSelectedStreamId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useSelector((state) => state.auth);

  // Fetch courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${Base_URL}/stream-course`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setCourses(response.data);
      } catch (error) {
        console.error('Error fetching courses:', error);
        toast.error('Failed to load courses');
      }
    };

    fetchCourses();
  }, [token]);

  const handleCourseChange = (e) => {
    const courseTitle = e.target.value;
    setSelectedCourse(courseTitle);

    // Find the selected course's stream_id
    const course = courses.find((c) => c.title === courseTitle);
    setSelectedStreamId(course ? course.id : null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!manualTitle && (!selectedCourse || !selectedStreamId)) {
      toast.error('Please select a course or enter a title manually!');
      return;
    }

    setIsLoading(true);

    try {
      const formData = {
        title: manualTitle || selectedCourse,
        created_by: 1,
        stream_course_id: selectedStreamId,
      };

      await axios.post(`${Base_URL}/branch`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      toast.success('Career successfully created!');

      // Reset form
      setSelectedCourse('');
      setManualTitle('');
      setSelectedStreamId(null);
    } catch (error) {
      console.error('Error submitting career data:', error);
      if (error.response?.status === 401) {
        toast.error('Unauthorized. Please login again.');
      } else {
        toast.error(error.response?.data?.message || 'Failed to create career');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 p-6">
      <HeaderSection title="Create Career" breadcrumb={['Career', 'Create Career']} />
      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div
            className="font-poppins"
            style={{
              color: 'rgb(49, 42, 42)',
              fontSize: '18.5px',
              fontWeight: 600,
              lineHeight: '26.25px',
              height: '45px',
            }}
          >
            Create Career
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Dropdown for course selection */}
            <div className="flex flex-col">
              <label htmlFor="course" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Select Course
              </label>
              <select
                id="course"
                name="course"
                value={selectedCourse}
                onChange={handleCourseChange}
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
              >
                <option value="">-- Select Course --</option>
                {courses.map((course) => (
                  <option key={course.id} value={course.title}>
                    {course.title}
                  </option>
                ))}
              </select>
            </div>

            {/* Manual title input */}
            <div className="flex flex-col">
              <label htmlFor="manualTitle" className="text-[#312a2a] font-poppins font-[500] text-[0.875rem]">
                Add Title 
              </label>
              <input
                type="text"
                id="manualTitle"
                name="manualTitle"
                value={manualTitle}
                onChange={(e) => setManualTitle(e.target.value)}
                placeholder="Enter a title"
                className="border border-gray-300 rounded-md p-2 mt-2 w-full"
              />
            </div>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="mt-4 px-4 py-2 bg-cmsgreen hover:bg-hovergreen text-white rounded-md flex items-center justify-center"
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Career'}
          </button>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default CareerForm;
