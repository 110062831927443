import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FaSort } from 'react-icons/fa';
import { IoArrowDownOutline, IoArrowUpOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Base_URL } from '../../../../../../apiConfig';
import HeaderSection from '../../../../../../dashboard/sidebarpages/headerSection/HeaderSection';

const StudentLeads = () => {
  const [registrationLeads, setRegistrationLeads] = useState([]);
  const [expertHelpLeads, setExpertHelpLeads] = useState([]);
  const [counsellingLeads, setCounsellingLeads] = useState([]);
  const [applicationRequestLeads, setApplicationRequestLeads] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTableData = async (endpoint, setState) => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_URL}${endpoint}`);
        setState(response.data);
      } catch (err) {
        toast.error(`Failed to fetch data from ${endpoint}`);
      } finally {
        setLoading(false);
      }
    };

    fetchTableData('/get/registration', setRegistrationLeads);
    fetchTableData('/get/expert_help', setExpertHelpLeads);
    fetchTableData('/get/counselling', setCounsellingLeads);
    fetchTableData('/get/application_request_university', setApplicationRequestLeads);
  }, []);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <IoArrowUpOutline /> : <IoArrowDownOutline />;
    }
    return <FaSort color='lightgrey' />;
  };

  const getSortedAndFilteredData = (data) => {
    let sortableData = [...data];
    if (sortConfig.key) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
      });
    }
    return sortableData.filter((lead) => lead.name?.toLowerCase().includes(searchTerm.toLowerCase()));
  };

  const getCurrentEntries = (data) => {
    const indexOfLastEntry = currentPage * entriesPerPage;
    const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
    return data.slice(indexOfFirstEntry, indexOfLastEntry);
  };

  const renderTable = (title, data) => {
    const sortedAndFilteredData = getSortedAndFilteredData(data);
    const currentEntries = getCurrentEntries(sortedAndFilteredData);
    const totalPages = Math.ceil(sortedAndFilteredData.length / entriesPerPage);

    const tableCellStyle = {
      color: 'rgb(115, 123, 139)',
      fontFamily: 'Poppins, sans-serif',
      fontSize: '13.125px',
      fontWeight: 400,
      lineHeight: '19.6px',
      padding: '9.1px 13.125px',
      borderBottomColor: 'rgb(230, 230, 230)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '150px',
    };
    const getStatusStyle = (status) => {
      switch (status) {
        case 1:
          return { backgroundColor: '#E7F5EA', color: '#27AE60', padding: '4px 8px', borderRadius: '5px' };
        case 0:
          return { backgroundColor: '#FEF5E7', color: '#E67E22', padding: '4px 8px', borderRadius: '5px' };
        default:
          return {};
      }
    };
  
    // const handleDetailsClick = (lead) => navigate(`/dashboard/leads/details/${lead.id}`, { state: { lead } });

    return (
      <div className="bg-white p-6 rounded-lg shadow mb-6">
        <div className="flex items-center justify-between mb-4 pb-2 border-b border-gray-200">
          <div className="text-gray-800 font-poppins text-lg font-semibold">{title}</div>
        </div>

        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className="border border-gray-300 rounded"
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center space-x-4">
            <span className="text-sm text-gray-600">Search:</span>
            <input
              type="text"
              className="px-3 py-1 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: '30px',
                width: '200px',
                borderRadius: '5px',
                borderColor: '#d0d0d0',
                padding: '0 10px',
              }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                {['S.No', 'Name', 'Mobile Number','Created On', 'Status'].map((header, index) => (
                  <th
                    key={index}
                    className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700 cursor-pointer"
                    onClick={() => handleSort(header.toLowerCase().replace(/ /g, '_'))}
                  >
                    <div className="flex flex-row items-center">
                      <div>{header}</div>
                      <div className="ml-1">{getSortIcon(header.toLowerCase().replace(/ /g, '_'))}</div>
                    </div>
                  </th>
                ))}
                 <th className="text-left border-b px-4 py-2 text-sm font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.length > 0 ? (
                currentEntries.map((lead, idx) => (
                  <tr key={lead.id} className="hover:bg-gray-50 border-b">
                    <td  style={{ ...tableCellStyle }}>{idx + 1}</td>
                    <td style={{ ...tableCellStyle }}>{lead.name}</td>
                    <td  style={{ ...tableCellStyle }}>{lead.mobilenumber}</td>
                    <td  style={{ ...tableCellStyle }}>{lead.created_on?.split('T')[0] || 'N/A'}</td>
                    <td style={{ ...tableCellStyle }}>
                          <span style={{ ...getStatusStyle(lead.status) }}>{lead.status === 1 ? 'Active' : 'Inactive'}</span>
                        </td>
                        <td style={tableCellStyle}>
                          <div className="underline text-cmsgreen hover:text-hovergreen cursor-pointer"
                          //  onClick={() => handleDetailsClick(lead)}
                           >
                            View Details
                          </div>
                        </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center py-4">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="text-sm text-gray-600">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
            className="px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-[#5BDE50]"
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <HeaderSection title="All Leads" breadcrumb={['Lead Management', 'All Leads']} />
      {renderTable('Registration Leads', registrationLeads)}
      {renderTable('Expert Help Leads', expertHelpLeads)}
      {renderTable('Counselling Leads', counsellingLeads)}
      {renderTable('Application Request Leads', applicationRequestLeads)}
    </div>
  );
};

export default StudentLeads
