import React, { useState, useEffect } from "react";
import axios from "axios";
import { Base_URL } from "../../apiConfig";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const UploadDocuments = () => {
  const { token } = useSelector((state) => state.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [submittedDocs, setSubmittedDocs] = useState({});
  const [downloadUrls, setDownloadUrls] = useState({});

  const documents = [
    {
      id: "10th",
      title: "10th Certificate",
      description: "Upload your 10th Certificate",
    },
    {
      id: "12th",
      title: "12th Certificate",
      description: "Upload your 12th Certificate.",
    },
    {
      id: "adhar_card",
      title: "Aadhaar Card",
      description: "Upload your Aadhar Card.",
    },
    {
      id: "pan_card",
      title: "PAN Card",
      description: "Upload your Pan Card",
    },
  ];

  const fetchProfile = async () => {
    if (!token) return;

    try {
      const response = await axios.get(`${Base_URL}/student/profile`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const profileData = response.data[0];
      if (profileData && profileData.documents) {
        const docMap = {};
        const urlMap = {};

        profileData.documents.forEach(doc => {
          docMap[doc.doc_type] = {
            name: doc.file_name,
            id: doc.id
          };
          urlMap[doc.doc_type] = `${Base_URL}/documents/${doc.id}`;
        });

        setSubmittedDocs(docMap);
        setDownloadUrls(urlMap);
      }
    } catch (error) {
      console.error("Error fetching existing documents:", error);
      
    }
  };

  useEffect(() => {
    fetchProfile();
  }, [token]);

  const handleFileUpload = (event, docId) => {
    // Check if document is already submitted
    if (submittedDocs[docId]) {
  
      event.target.value = ''; // Reset file input
      return;
    }

    const file = event.target.files[0];
    if (!file) return;

    if (file.type !== "application/pdf") {
      setErrors(prev => ({ ...prev, [docId]: "Only PDF files are allowed." }));
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      setErrors(prev => ({ ...prev, [docId]: "File size exceeds 2MB." }));
      return;
    }

    setErrors(prev => ({ ...prev, [docId]: null }));
    setUploadedFiles(prev => ({ ...prev, [docId]: file }));
  };

  const handleDownload = async (docId) => {
    if (!downloadUrls[docId]) {
      toast.error("Document not available for download");
      return;
    }

    try {
      const response = await axios.get(downloadUrls[docId], {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob'
      });

      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${submittedDocs[docId].name}`);
      
      // Append to html link element page
      document.body.appendChild(link);
      
      // Start download
      link.click();
      
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading document:", error);
      toast.error("Failed to download document");
    }
  };

  const handleSubmit = async () => {
    if (Object.keys(uploadedFiles).length === 0) {
      toast.warning("Please upload at least one document");
      return;
    }
  
    setIsSubmitting(true);
  
    try {
      const formData = new FormData();
      
      Object.entries(uploadedFiles).forEach(([docType, file]) => {
        formData.append(docType, file);
      });
  
      const response = await axios.post(`${Base_URL}/documents`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
  
      toast.success(response.data.message || "Documents uploaded successfully");
      setUploadedFiles({});
      fetchProfile(); 
    } catch (error) {
      console.error("Error uploading documents:", error);
      toast.error(error.response?.data?.message || "Failed to upload documents");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      {/* Warning Banner */}
      <div className="w-full max-w-[1040px] mb-6 relative overflow-hidden">
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 flex items-center">
          <div className="flex-1">
            <p className="text-yellow-700 font-medium">
              Important Notice
            </p>
            <p className="text-yellow-600 text-sm mt-1">
              Please ensure all uploaded documents are valid and authentic. Documents cannot be modified once submitted. 
              The admission process will be cancelled if any submitted documents are found to be invalid.
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#00000040] w-full max-w-[1040px] p-6">
        <h2 className="text-xl font-bold mb-4 text-center">Documents</h2>
        <div className="flex flex-wrap justify-center gap-6">
          {documents.map((doc) => (
            <div
              key={doc.id}
              className="w-[190px] h-[300px] border border-[#00000040] rounded-md p-4 bg-white shadow-sm hover:shadow-md transition-shadow flex flex-col"
            >
              <div className="flex flex-col items-center gap-4">
                <h3 className="text-[16px] font-bold text-center">
                  {doc.title}
                </h3>
                
                <label
                  htmlFor={`upload-${doc.id}`}
                  className={`w-[150px] h-[65px] flex items-center justify-center rounded-md transition-colors ${
                    submittedDocs[doc.id]
                      ? 'bg-gray-100 border border-gray-300 cursor-not-allowed'
                      : 'bg-[#DBFDC3] border border-[#3ACA2E] cursor-pointer hover:bg-[#cff8b1]'
                  }`}
                >
                  <svg
                    width="26"
                    height="25"
                    viewBox="0 0 26 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`mr-2 ${submittedDocs[doc.id] ? 'opacity-50' : ''}`}
                  >
                    <path
                      d="M20.216 11.3867C20.216 11.3281 20.2262 11.2695 20.2262 11.2109C20.2262 8.03711 17.5957 5.46875 14.3508 5.46875C12.0098 5.46875 9.99883 6.80664 9.0543 8.74023C8.64297 8.54004 8.18086 8.42285 7.69336 8.42285C6.19531 8.42285 4.94609 9.49219 4.70742 10.8887C2.90977 11.4844 1.625 13.1396 1.625 15.0879C1.625 17.5391 3.66133 19.5312 6.16992 19.5312H11.375V15.625H8.92734L13 11.5381L17.0727 15.6201H14.625V19.5264H20.2262C22.5215 19.5264 24.375 17.6953 24.375 15.4541C24.375 13.2129 22.5113 11.3916 20.216 11.3867Z"
                      fill={submittedDocs[doc.id] ? "#999" : "black"}
                    />
                  </svg>
                  <span className={`text-sm font-bold ${submittedDocs[doc.id] ? 'text-gray-500' : ''}`}>
                    {submittedDocs[doc.id] ? 'Submitted' : 'Upload'}
                  </span>
                </label>
                <input
                  id={`upload-${doc.id}`}
                  type="file"
                  accept=".pdf"
                  className="hidden"
                  onChange={(e) => handleFileUpload(e, doc.id)}
                  disabled={submittedDocs[doc.id]}
                />

                {submittedDocs[doc.id] && (
                  <p className="text-xs text-gray-500 text-center">
                    Document already submitted
                  </p>
                )}
              </div>

              <div className="mt-4">
                <p className="text-[12px] font-medium text-gray-600 text-center">
                  {doc.description}
                </p>
              </div>

              <div className="mt-auto pt-4 border-t">
                {(uploadedFiles[doc.id] || submittedDocs[doc.id]) && (
                  <p className="text-[12px] text-gray-600 truncate text-center mb-2">
                    {uploadedFiles[doc.id]?.name || submittedDocs[doc.id]?.name}
                  </p>
                )}
                
                <button
                  className={`w-full py-2 text-white text-sm font-semibold rounded-md transition-colors ${
                    downloadUrls[doc.id]
                      ? 'bg-[#3ACA2E] hover:bg-[#2fb625] cursor-pointer'
                      : 'bg-gray-400 cursor-not-allowed'
                  }`}
                  onClick={() => handleDownload(doc.id)}
                  disabled={!downloadUrls[doc.id]}
                >
                  Download
                </button>
              </div>

              {errors[doc.id] && (
                <p className="text-red-500 text-[12px] text-center mt-2">
                  {errors[doc.id]}
                </p>
              )}
            </div>
          ))}
        </div>
        
        <div className="flex justify-end mt-6">
          <button
            className="px-6 py-2 bg-[#3ACA2E] text-white font-bold rounded-md shadow-md hover:bg-[#2fb625] transition-colors disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={handleSubmit}
            disabled={isSubmitting || Object.keys(uploadedFiles).length === 0}
          >
            {isSubmitting ? (
              <div className="flex items-center space-x-2">
                <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
                <span>Submitting...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;