import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FiHome, FiUserPlus, FiUsers ,FiUser } from "react-icons/fi";

const PartnerSidebar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const menuItems = [
    { name: "Dashboard", path: "/certified-partner-dashboard", icon: <FiHome />, end: true },
    { name: "Add Student", path: "/certified-partner-dashboard/add-students", icon: <FiUserPlus />, end: false },
    { name: "View Students", path: "/certified-partner-dashboard/view-students", icon: <FiUsers />, end: false },
    { name: "My Profile", path: "/certified-partner-dashboard/profile", icon: <FiUser />, end: false },
  ];

  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* Mobile and Tablet Sidebar */}
      <div
        className={`block xl:hidden ${
          isSticky ? "sticky top-0 shadow-lg" : "relative"
        } bg-white z-10`}
      >
        <div className="flex overflow-x-auto scrollbar-hide p-4 space-x-4">
          {menuItems.map((item) => (
            <NavLink
              key={item.name}
              to={item.path}
              end={item.end} // Use the `end` prop for exact matching
              className={({ isActive }) =>
                `flex items-center gap-2 px-4 py-2 rounded-lg whitespace-nowrap ${
                  isActive ? "text-cmsgreen" : "text-textGray"
                }`
              }
            >
              <span className="text-cmsgreen text-xl">{item.icon}</span>
              <span className="text-sm font-medium">{item.name}</span>
            </NavLink>
          ))}
        </div>
      </div>

      {/* Desktop Sidebar */}
      <div className="hidden xl:block border border-gray-300 m-12 shadow-lg rounded-md p-6">
        <ul className="space-y-4">
          {menuItems.map((item, index) => (
            <React.Fragment key={item.name}>
              <li>
                <NavLink
                  to={item.path}
                  end={item.end} // Use the `end` prop for exact matching
                  className={({ isActive }) =>
                    `flex items-center gap-1 px-2 rounded-lg ${
                      isActive ? "text-cmsgreen font-semibold" : "text-black"
                    }`
                  }
                >
                  <span className="text-cmsgreen text-xl">{item.icon}</span>
                  {item.name}
                </NavLink>
              </li>
              {/* Add a separator for all except the last item */}
              {index < menuItems.length - 1 && <hr className="border-gray-300" />}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </>
  );
};

export default PartnerSidebar;
