import React, { useEffect, useState } from "react";
import { FaFireAlt } from "react-icons/fa";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import {
  fetchNewsAdminByTitle,
  fetchNewsAnnouncements,
  fetchNewsByTitle,
} from "../api/services/NewsEventService";
import { Img_URL } from "../apiConfig";
import HatCircleLoader from "../components/Loader/HatCircleLOader";

const ParticularNews = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [currentNewsItem, setCurrentNewsItem] = useState(location.state?.newsItem || null);
  const [trendingNews, setTrendingNews] = useState([]);
  const [loading, setLoading] = useState(!location.state?.newsItem);

  useEffect(() => {
    if (location.state?.newsItem) {
      const newsItem = location.state.newsItem;
      const formattedDate = formatDate(newsItem.announcement_date || newsItem.created_at);
      setCurrentNewsItem({
        ...newsItem,
        formattedDate: formattedDate,
        formattedUpdatedDate: formattedDate
      });
    }
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!location.state?.newsItem) {
          setLoading(true);
          const title = decodeURIComponent(id).replace(/-/g, " ");

          if (location.pathname.includes("college")) {
            const newsByCollegeResponse = await fetchNewsAdminByTitle(title);
            if (newsByCollegeResponse.length > 0) {
              const newsItem = newsByCollegeResponse[0];
              const formattedDate = formatDate(newsItem.announcement_date);
              setCurrentNewsItem({
                ...newsItem,
                formattedDate: formattedDate,
                formattedUpdatedDate: formattedDate
              });
            }
          } else {
            const newsByIdResponse = await fetchNewsByTitle(title);
            if (newsByIdResponse.length > 0) {
              const newsItem = newsByIdResponse[0];
              const formattedDate = formatDate(newsItem.announcement_date);
              setCurrentNewsItem({
                ...newsItem,
                formattedDate: formattedDate,
                formattedUpdatedDate: formattedDate
              });
            }
          }
        }

        // Fetch trending news
        const trendingNewsResponse = await fetchNewsAnnouncements();
        const trendingItems = trendingNewsResponse
          .filter((news) => news.trending_news === 1)
          .slice(0, 4);
        setTrendingNews(trendingItems);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, location.pathname]);

  const formatDate = (date) => {
    if (!date) return "";
    try {
      return new Date(date).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
    } catch (error) {
      console.error("Error formatting date:", error);
      return "";
    }
  };

  const handleTrendingNewsClick = (item) => {
    const formattedTitle = item.title.trim().replace(/\s+/g, "-");
    const formattedDate = formatDate(item.announcement_date || item.created_at);
    
    // First update the current news item state
    const updatedNewsItem = {
      ...item,
      announcement_date: item.announcement_date || item.created_at,
      picture: item.picture ? `/files${item.picture}` : null,
      user_name: item.author_name || item.user_name,
      formattedDate: formattedDate,
      formattedUpdatedDate: formattedDate
    };
    
    // Update state immediately
    setCurrentNewsItem(updatedNewsItem);

    // Update URL without causing a refresh
    navigate(`/particularNews/${formattedTitle}`, {
      state: { newsItem: updatedNewsItem },
      replace: true
    });
  };

  if (loading) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <HatCircleLoader />
      </div>
    );
  }

  if (!currentNewsItem) {
    return (
      <div className='flex justify-center items-center h-screen'>
        <p>News not found</p>
      </div>
    );
  }

  return (
    <div className='w-full'>
      {/* Section Below Header */}
      <div className='w-full py-8 bg-[rgba(0,0,0,0.5)] mt-[40px]'>
        <h1 className='text-left text-white text-[16px] font-bold px-6'>
          {currentNewsItem.title}
        </h1>
        <p className='text-left text-white text-sm px-6 mt-2'>
          Created on {formatDate(currentNewsItem.announcement_date || currentNewsItem.created_at)}
        </p>
      </div>

      {/* Main Content */}
      <div className='w-full max-w-7xl mx-auto py-12 flex flex-wrap lg:flex-nowrap mt-8 px-4 gap-8'>
        {/* Particular News Section */}
        <div className='flex-1 space-y-6'>
          <p className='text-green-600 font-bold text-lg'>
            {currentNewsItem.editor_name || currentNewsItem.user_name}
          </p>
          <p className='text-gray-500 text-sm mb-4'>
            {formatDate(currentNewsItem.announcement_date || currentNewsItem.created_at)}
          </p>

          <div
            className='text-gray-700 font-semibold p-4'
            style={{
              borderLeft: "5px solid #58CD03",
            }}
          >
            <p className='text-[24px] text-black font-[600]'>
              {currentNewsItem.title}
            </p>
          </div>

          {currentNewsItem.picture ? (
            <img
              src={currentNewsItem.picture.startsWith('/') ? `${Img_URL}${currentNewsItem.picture}` : `${Img_URL}/${currentNewsItem.picture}`}
              alt={currentNewsItem.title}
              className='w-full max-w-[800px] h-[400px] object-cover rounded-md'
            />
          ) : (
            <div className='w-full max-w-[800px] h-[400px] bg-gray-300 rounded-md flex items-center justify-center'>
              No Image
            </div>
          )}

          <p className='text-gray-700 font-semibold leading-relaxed break-words'>
            {currentNewsItem.content}
          </p>
          {currentNewsItem.sub_content && (
            <p className='text-gray-700 font-semibold leading-relaxed break-words'>
              {currentNewsItem.sub_content}
            </p>
          )}
        </div>

        {/* Trending News Section */}
        <div className='w-full lg:w-[350px] space-y-6'>
          <div className='flex items-center space-x-2'>
            <FaFireAlt className='text-black text-2xl' />
            <h2 className='text-black text-xl font-bold'>Trending News</h2>
          </div>

          <div className='space-y-4'>
            {trendingNews.map((item) => {
              const trendingNewsDate = formatDate(item.announcement_date || item.created_at);

              return (
                <div
                  key={item.id}
                  onClick={() => handleTrendingNewsClick(item)}
                  className='flex flex-col items-start p-4 bg-white rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow'
                >
                  <div className='flex items-center'>
                    <img
                      src={`${Img_URL}/files${item.picture}`}
                      alt={item.title}
                      className='h-[100px] w-[100px] object-cover rounded-lg mr-4'
                    />
                    <div className='flex-1'>
                      <h3 className='text-black text-sm font-semibold line-clamp-1 mb-1'>
                        {item.title}
                      </h3>
                      <p className='text-gray-500 text-xs mb-2'>
                        By - {item.author_name || item.user_name} | {trendingNewsDate}
                      </p>
                    </div>
                  </div>
                  <p className='text-black text-[500] text-xs mt-auto line-clamp-2'>
                    {item.sub_content}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticularNews;
