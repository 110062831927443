import React from 'react';
import { HiOutlineCurrencyDollar, HiBriefcase } from 'react-icons/hi';

const TelecallerMenu = () => {
  const telecallerMenuItems = [
    {
      title: 'Telecaller Dashboard',
      icon: HiBriefcase,
      items: [
        { title: 'All Leads', path: '/admin/telecaller/students-leads' },
       
      ],
    },
    // {
    //   title: 'Telecaller Tasks',
    //   icon: HiOutlineCurrencyDollar,
    //   items: [
    //     { title: 'All Tasks', path: '/telecaller/tasks/all' },
    //     { title: 'Add Task', path: '/telecaller/tasks/add' },
    //   ],
    // },
  ];

  return telecallerMenuItems;
};

export default TelecallerMenu;
