import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Base_URL, Img_URL } from "../apiConfig";
import { useSelector } from 'react-redux';

const CareerTab = () => {
  const { stream_name } = useParams(); // Get stream_name from URL
  const [programs, setPrograms] = useState([]); // Store filtered programs
  const [streamName, setStreamName] = useState(""); // Store the stream name
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const normalizedStreamName = stream_name.replace(/-/g, " "); // Convert hyphens to spaces
        setStreamName(normalizedStreamName);

        const response = await axios.get(`${Base_URL}/stream-course`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        console.log("Full API Response:", response.data);

        // Filter programs based on the normalized stream_name
        const filteredPrograms = response.data.filter(
          (program) =>
            program.stream_name.trim().toLowerCase() ===
            normalizedStreamName.trim().toLowerCase()
        );

        console.log("Filtered Programs:", filteredPrograms);

        setPrograms(filteredPrograms);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, [stream_name]);

  const handleButtonClick = (program) => {
    const formattedTitle = program.title.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/program/${formattedTitle}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow p-6 mt-20 ">
        <div className="container mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold text-center text-[#333] mt-8 mb-6">
      Explore Courses in {streamName}
</h2>


          <div className="grid grid-cols-2 gap-6 mt-20 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {programs.length > 0 ? (
              programs.map((program) => (
                <div
                  key={program.id}
                  className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
                >
                  <div className="absolute bottom-0 w-full">
                    <button
                      onClick={() => handleButtonClick(program)}
                      className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                    >
                      {program.title}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-4 text-center">
                No programs found for this stream.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerTab;
