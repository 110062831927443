import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { FaUser, FaUsers } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../../../apiConfig";
import { useSelector } from "react-redux";



const AddStudents = () => {
  const [activeCard, setActiveCard] = useState("single"); // Default to "single" card
  const [cities, setCities] = useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    city_id: null,
    mobilenumber: "",
   
  });
  const { registration_id, token, name } = useSelector((state) => state.auth);

  // Fetch city data on component mount
  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/city`);
        const cityOptions = response.data.map((city) => ({
          value: city.id,
          label: city.name,
        }));
        setCities(cityOptions);
      } catch (error) {
        console.error("Failed to fetch cities:", error);
        // toast.error("Failed to load city options.");
      }
    };
    fetchCity();
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle city change
  const handleCityChange = (selectedOption) => {
    setFormData({ ...formData, city_id: selectedOption.value });
  };

  // Handle file input change (for Excel file)
  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };

   const validateForm = () => {
      const nameRegex = /^[a-zA-Z\s]+$/; // Only alphabets and spaces
      const mobileRegex = /^[0-9]{10}$/; // Valid 10-digit number
      const { mobilenumber} = formData;
  
      if (!mobileRegex.test(mobilenumber)) {
        toast.error("Mobile number must be a valid 10-digit number.");
        return false;
      }
      return true;
    }
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Run validation before submission
    try {
      const payload = {
        name: formData.name,
        city_id: formData.city_id,
        mobilenumber: formData.mobilenumber,
      };
      const response = await axios.post(`${Base_URL}/create/referred_student`, payload ,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Ensure "Authorization" is properly spelled
          },
        }
      );
      toast.success("Student added successfully!");
      setFormData({ name: "", city_id: "", mobilenumber: "" }); // Reset form
    } catch (error) {
      console.error("Failed to add student:", error);
      toast.error(`Failed to add student.${error.response.data.message}`);
    }
  };

    // Handle form submission for multiple students
    const handleSubmitMultiple = async (e) => {
      e.preventDefault();
  
      if (!excelFile) {
        toast.error("Please upload an Excel file.");
        return;
      }
  
      const formData = new FormData();
      formData.append("file", excelFile); // Append the file to FormData
  
      try {
        const response = await axios.post(
          `${Base_URL}/files/upload-students`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`, // Ensure "Authorization" is properly spelled
            },
          }
        );
        toast.success("Multiple students added successfully!");
        setExcelFile(null); // Reset the file state
      } catch (error) {
        console.error("Failed to upload Excel file:", error);
        toast.error(`Failed to upload students.${error.response?.data?.message || error.message}`);
      }
    };
  
  

  return (
    <div className="p-6">
       <ToastContainer/>
      {/* Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
       
        {/* Single Student Card */}
        <div
          className={`flex items-center justify-between p-4 rounded-lg shadow-md cursor-pointer ${
            activeCard === "single" ? "border-2 border-cmsgreen" : ""
          }`}
          style={{ backgroundColor: "#e8f5e9" }}
          onClick={() => setActiveCard("single")}
        >
          <div>
            <h3 className="text-xl font-bold text-gray-800">Add Student</h3>
            <p className="text-sm text-gray-600">Add a single student's detail</p>
          </div>
          <FaUser className="text-4xl text-cmsgreen" />
        </div>

        {/* Multiple Students Card */}
        <div
          className={`flex items-center justify-between p-4 rounded-lg shadow-md cursor-pointer ${
            activeCard === "multiple" ? "border-2 border-cmsgreen" : ""
          }`}
          style={{ backgroundColor: "#e8f5e9" }}
          onClick={() => setActiveCard("multiple")}
        >
          <div>
            <h3 className="text-xl font-bold text-gray-800">Add Multiple Students</h3>
            <p className="text-sm text-gray-600">
              Add multiple student details by uploading an Excel sheet
            </p>
          </div>
          <FaUsers className="text-4xl text-cmsgreen" />
        </div>
      </div>

      {/* Forms */}
      <div className="bg-white rounded-lg shadow-md p-6">
        {activeCard === "single" ? (
          <form onSubmit={handleSubmit}>
            <h3 className="text-lg font-bold text-gray-800 mb-4">Add Single Student</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="name" className="block text-gray-600 mb-1">
                  Student Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  placeholder="Enter student name"
                  required
                />
              </div>
              <div>
                <label htmlFor="mobilenumber" className="block text-gray-600 mb-1">
                  Contact Number
                </label>
                <input
                  id="mobilenumber"
                  name="mobilenumber"
                  type="text"
                  value={formData.mobilenumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  placeholder="Enter contact number"
                  required
                />
              </div>
              {/* <div>
                <label htmlFor="email" className="block text-gray-600 mb-1">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border-slate-300 rounded-lg focus:border-cmsgreen focus:ring-cmsgreen focus:outline-none"
                  placeholder="Enter email"
                />
              </div> */}
              <div className="flex flex-col">
                <label htmlFor="city" className="mb-2 font-medium text-sm">
                  Select City
                </label>
                <Select
                  options={cities}
                  onChange={handleCityChange}
                  placeholder="Select City"
                  className="basic-single"
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="bg-cmsgreen hover:bg-hovergreen text-white px-4 py-2 mt-6 rounded-lg"
            >
              Submit
            </button>
          </form>
        ) : (
          <form onSubmit={handleSubmitMultiple}>
          <h3 className="text-lg font-bold text-gray-800 mb-4">Add Multiple Students</h3>
           {/* Button to download Excel format */}
  <a
    href={`${Base_URL}/files/referred_students_format.xlsx`}
    rel="noopener noreferrer"
    className="bg-gray-100 hover:bg-gray-200 text-cmsgreen px-4 py-2 rounded-lg inline-block mb-4"
  >
    Download Excel Format
  </a>
          <label htmlFor="excelUpload" className="block text-gray-600 mb-1">
            Upload Excel File
          </label>
          <input
            id="excelUpload"
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            className="block w-full border rounded-md mb-4"
            required
          />
          <button
            type="submit"
            className="bg-cmsgreen hover:bg-hovergreen text-white px-4 py-2 mt-4 rounded-lg"
          >
            Upload
          </button>
        </form>
        )}
      </div>
    </div>
  );
};

export default AddStudents;
