import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import CareerSearch from "./CareerSearch";

const CareersGrid = () => {
  const [streams, setStreams] = useState([]); // Holds all career streams
  const [filteredStreams, setFilteredStreams] = useState([]); // Holds filtered streams
  const navigate = useNavigate();

  // Fetch all streams from backend
  useEffect(() => {
    const fetchStreams = async () => {
      try {
        const response = await fetch(`${Base_URL}/get/streams`);
        const data = await response.json();
        const validData = Array.isArray(data) ? data : [];
        setStreams(validData);
        setFilteredStreams(validData); // Set initial streams
      } catch (error) {
        console.error("Error fetching streams:", error);
      }
    };

    fetchStreams();
  }, []);

  // Navigate to specific career tab
  const handleStreamSelect = (stream) => {
    const formattedStreamName = stream.stream_name.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/career/tab/${encodeURIComponent(formattedStreamName)}`);
  };

  // Handle search and dynamically filter the streams
  const handleSearch = (query) => {
    const filteredResults = streams.filter((stream) =>
      stream.stream_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredStreams(filteredResults);
  };

  return (
    <div className="flex flex-col items-center w-full px-4 md:px-6 lg:px-8 py-4 sm:py-8">
      <div className="w-full ">
        {/* Pass search query changes to parent */}
        <CareerSearch onSearchChange={handleSearch} />
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mt-[60px]  w-full">
          {Array.isArray(filteredStreams) && filteredStreams.length > 0 ? (
            filteredStreams.map((stream) => (
              <div
                key={stream.id}
                className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
              >
                <div className="absolute bottom-0 w-full">
                  <button
                    onClick={() => handleStreamSelect(stream)}
                    className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                  >
                    {stream.stream_name}
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="col-span-full text-center text-gray-600">
              No Streams Found
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareersGrid;
