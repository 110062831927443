import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StudentDashboardSidebar from './StudentDashboardSidebar/StudentDashboardSidebar';
import StudentProfile from './StudentDashboardSidebar/StudentProfile';
import StudentDashboard from './StudentDashboard';
import WishlistedColleges from './StudentDashboardSidebar/WishlistedColleges';
import UploadDocument from './StudentDashboardSidebar/UploadDocuments';
import CareersGrid from "../pages/CareersGrid";
const DashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState('Dashboard');
  
  // Redirect to dashboard if on root path
  useEffect(() => {
    if (location.pathname === '/student-dashboard/') {
      navigate('/student-dashboard');
    }
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location.pathname, activeComponent, navigate]);
  
  // Render content based on active component
  const renderContent = () => {
    switch (activeComponent) {
      case 'Dashboard':
        return <StudentDashboard onCompleteProfile={() => setActiveComponent('My Profile')} />;
      case 'My Profile':
        return <StudentProfile />;
      case 'College Admission':
        return <WishlistedColleges />;
      case 'Counsellors':
        return <div className="p-4">Counsellors content goes here</div>;
      case 'Payment History':
        return <div className="p-4">Payment History content goes here</div>;
      case 'Referrals & Rewards':
        return <div className="p-4">Referrals & Rewards content goes here</div>;
      case 'Documents':
        return <UploadDocument />;
      // case 'Career':
      //   return <div className="p-4 "><CareersGrid /></div>;
      case 'Account Settings':
        return <div className="p-4">Account Settings content goes here</div>;
      default:
        return <StudentDashboard onCompleteProfile={() => setActiveComponent('My Profile')} />;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      

      {/* Main Content Container */}
      <div className="min-h-screen pt-[64px]">
        {/* Sidebar that starts right after navbar */}
        <StudentDashboardSidebar 
          activeComponent={activeComponent} 
          setActiveComponent={setActiveComponent} 
        />

        {/* Main Content */}
        <div className="flex-grow">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-[1200px] pt-4">
            <div className="w-full max-w-full overflow-x-hidden">
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
