import React, { useEffect, useState } from 'react';
import { HiPencil, HiTrash } from 'react-icons/hi';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderSection from '../headerSection/HeaderSection';
//import dummyAmbassadors from './dummyAmbassadors';
import axios from 'axios';
import { Base_URL } from '../../../apiConfig';


const AllAmbassadors = () => {
  const [ambassadors, setAmbassadors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = ambassadors.slice(
    indexOfFirstEntry,
    indexOfFirstEntry + entriesPerPage
  );

  const totalPages = Math.ceil(ambassadors.length / entriesPerPage);
  const getStatusText = (status) => {
    return status === 1 ? "Active" : "Inactive";
  };
  useEffect(() => {
    const fetchAmbassadors = async () => {
      try {
        const response = await axios.get(
          `${Base_URL}/ambassadors/university/1`
        );

        setAmbassadors(response.data);
      } catch (error) {
        console.error(
          "Error fetching ambassadors:",
          error.response ? error.response.data : error.message
        );
      }
    };

    fetchAmbassadors();
  }, []);

  useEffect(() => {
    if (location.state?.updatedAmbassador) {
      const updatedAmbassador = location.state.updatedAmbassador;
      setAmbassadors((prevAmbassadors) => {
        const filtered = prevAmbassadors.filter(
          (ambassador) => ambassador.id !== updatedAmbassador.id
        );
        return [updatedAmbassador, ...filtered];
      });
    }
  }, [location.state]);
  const handleEditClick = (id) => {
    navigate(`/dashboard/ambassadors/edit/${id}`);
  };

  const handleDeleteClick = async (id) => {
    const universityId = 1; // Ensure this is the correct university ID
    if (window.confirm("Are you sure you want to delete this ambassador?")) {
      try {
        const url = `${Base_URL}/ambassadors/${id}`;

        await axios.delete(url);
        setAmbassadors((prevAmbassadors) =>
          prevAmbassadors.filter((ambassador) => ambassador.id !== id)
        );
        alert("ambassador deleted successfully.");
      } catch (error) {
        console.error(
          "Error deleting ambassador:",
          error.response ? error.response.data : error.message
        );
        setError("Failed to delete ambassador.");
      }
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const filteredEntries = currentEntries.filter((ambassador) =>
    ambassador.name
      ? ambassador.name.toLowerCase().includes(searchTerm.toLowerCase())
      : false
  );

  return (
    <div className='p-6 bg-gray-100 min-h-screen'>
      <HeaderSection
        title='All Ambassadors'
        breadcrumb={["Ambassadors", "All Ambassadors"]}
      />

      <div className='bg-white p-6 rounded-lg shadow'>
        <div className='flex items-center justify-between mb-4 pb-2 border-b border-gray-200'>
          <div
            className='font-poppins'
            style={{
              color: "rgb(49, 42, 42)",
              fontSize: "18.5px",
              fontWeight: 600,
              lineHeight: "26.25px",
              height: "45px",
            }}
          >
            All Ambassadors
          </div>
          <div className='flex items-center space-x-4'>
            <button
              className='px-4 py-2  text-white rounded-bl-[5px] rounded-br-[5px] bg-customblue rounded text-sm hover:bg-[rgb(82,89,199)]'
              onClick={() => navigate("/dashboard/ambassadors/add")}
            >
              + Add New
            </button>
          </div>
        </div>
        <div className='flex items-center justify-between mb-4 pb-2 '>
          <div className='flex items-center space-x-4'>
            <span className='text-sm text-gray-600 mr-2'>Show:</span>
            <select
              value={entriesPerPage}
              onChange={(e) => setEntriesPerPage(Number(e.target.value))}
              className='border border-gray-300 rounded'
            >
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='50'>50</option>
              <option value='100'>100</option>
            </select>
          </div>

          <div className='flex items-center space-x-4'>
            <span
              className='text-sm text-gray-600'
              style={{
                color: "rgb(130, 130, 130)",
                fontFamily: "Poppins, sans-serif",
                fontSize: "14px",
              }}
            >
              Search:
            </span>
            <input
              type='text'
              className='px-3 py-1 border border-gray-300 rounded'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                height: "30px",
                width: "200px",
                borderRadius: "5px",
                borderColor: "#d0d0d0",
                padding: "0 10px",
              }}
            />
          </div>
        </div>

        <div className='overflow-x-auto'>
          <table className='min-w-full bg-white'>
            <thead>
              <tr className='bg-white'>
                {[
                  "Name",
                  "Email",
                  "subject",
                  "Introduction",
                  "Status",
                  "Actions",
                ].map((heading) => (
                  <th
                    key={heading}
                    className='text-left border-b'
                    style={{
                      color: "rgb(49, 42, 42)",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: "14px",
                      fontWeight: 600,
                      lineHeight: "21px",
                      padding: "14px 13px",
                      borderBottomColor: "rgb(230, 230, 230)",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredEntries.length > 0 ? (
                filteredEntries.map((ambassador) => (
                  <tr
                    key={ambassador.id}
                    className='hover:bg-white hover:shadow-md border-b'
                    style={{
                      borderTopWidth: "1px",
                      borderTopColor: "rgb(230, 230, 230)",
                      verticalAlign: "top",
                    }}
                  >
                    
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap", // Prevent line breaks
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis for overflow
                        maxWidth: "150px",
                      }}
                    >
                      {ambassador.name}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap", // Prevent line breaks
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis for overflow
                        maxWidth: "150px",
                      }}
                    >
                      {ambassador.email}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap", // Prevent line breaks
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis for overflow
                        maxWidth: "150px",
                      }}
                    >
                      {ambassador.subject}
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap", // Prevent line breaks
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis for overflow
                        maxWidth: "150px",
                      }}
                    >
                      {ambassador.introduction}
                    </td>

                    <td
                      className={`text-left border-t border-b ${
                        ambassador.status === 1
                          ? "border-green-500"
                          : "border-red-500"
                      }`}
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                      }}
                    >
                      <span
                        className={`inline-block py-1 px-3 rounded-full text-sm ${
                          ambassador.status === 1
                            ? "bg-green-100 text-green-600 border border-green-500"
                            : "bg-red-100 text-red-600 border border-red-500"
                        }`}
                      >
                        {getStatusText(ambassador.status)}
                      </span>
                    </td>
                    <td
                      className='text-left border-t border-b'
                      style={{
                        color: "rgb(115, 123, 139)",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "13.125px",
                        fontWeight: 400,
                        lineHeight: "19.6px",
                        padding: "9.1px 13.125px",
                        borderBottomColor: "rgb(230, 230, 230)",
                        whiteSpace: "nowrap", // Prevent line breaks
                        overflow: "hidden", // Hide overflow
                        textOverflow: "ellipsis", // Add ellipsis for overflow
                        maxWidth: "150px",
                      }}
                    >
                      <div className='flex space-x-2'>
                        <button
                          onClick={() => handleEditClick(ambassador.id)}
                          className='p-2 rounded bg-blue-500 text-white hover:bg-blue-700'
                        >
                          <HiPencil />
                        </button>
                        <button
                          onClick={() => handleDeleteClick(ambassador.id)}
                          className='p-2 rounded bg-red-500 text-white hover:bg-red-700'
                        >
                          <HiTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='8' className='text-center py-4 text-gray-600'>
                    No ambassadors found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className='flex items-center justify-between mt-4'>
          <button
            className='px-4 py-2  bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]'
            onClick={handlePrevious}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className='text-sm text-white'>
            Page {currentPage} of {totalPages}
          </span>
          <button
            className='px-4 py-2 bg-customblue text-white rounded text-sm hover:bg-[rgb(82,89,199)]'
            onClick={handleNext}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default AllAmbassadors;
