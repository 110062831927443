import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Base_URL } from "../../apiConfig";

const SimpleEnquiryForm = ({ isOpen, onClose, onSubmit, university_id, program_master_id, course_master_id, sub_courses_master_id }) => {
  const modalRef = useRef();
  const [formData, setFormData] = useState({
    name: "",
    mobilenumber: "",
    email:"",
 
  });

  const userData = useSelector((state) => state.auth);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    console.log('token',token)
    if (userData?.registration_id) {
      setFormData((prevData) => ({
        ...prevData,
        name: userData.name || "",
        mobilenumber: userData.mobilenumber || "",
      }));
    }
  }, [userData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Allow only numbers for the contact number field
    if (name === "mobilenumber" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that the contact number is a 10-digit number
    const mobileRegex = /^\d{10}$/; // Regular expression to match exactly 10 digits
    if (!mobileRegex.test(formData.mobilenumber)) {
      toast.error("Please enter a valid 10-digit contact number.");
      return;
    }

    // Prepare payload
    const payload = {
      name: formData.name,
      mobilenumber: formData.mobilenumber,
      email:null,
      university_id: `${university_id}`,
      program_master_id: course_master_id ? null : `${program_master_id}`,
      course_master_id: course_master_id ? `${course_master_id}` : null,
     
    };

    const authPayload={
      name: formData.name,
      mobilenumber: formData.contact_no,
      university_id: `${university_id}`,
      program_master_id: course_master_id ? null : `${program_master_id}`,
      course_master_id: course_master_id ? `${course_master_id}` : null,
    }

    if(token){




      try {
       const response= await axios.post(`${Base_URL}/apply-now`, authPayload, {
         headers: { Authorization: `Bearer ${token}` },
       
         });
         if (response.data) {
          toast.success("Application submitted successfully!");
          resetForm(); // Reset the form properly after successful submission
          
        }
       } catch (error) {
         if (error.response && error.response.data && error.response.data.message) {
           toast.error(error.response.data.message);
         } else {
           toast.error("Submission failed! Please try again.");
         }
       }

    }

    else{


    try {
      const response = await axios.post(`${Base_URL}/expert-help`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data) {
        toast.success("Application submitted successfully!");
        resetForm(); // Reset the form properly after successful submission
       
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Submission failed! Please try again.");
      }
    }
  };
}

  const resetForm = () => {
    setFormData({
      name: userData?.registration_id ? userData.name : "",
      mobilenumber: userData?.registration_id ? userData.mobilenumber : "",
    });
  };

  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='absolute inset-y-[150px] flex items-center justify-center w-full px-4'>
        <div
          ref={modalRef}
          className='bg-white rounded-lg p-6 w-full max-w-md mx-auto relative'
        >
          {/* Close Button */}
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 focus:outline-none"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className='flex justify-between items-center mb-6'>
            <h2 className='text-xl font-semibold text-gray-800'>
              Enquiry Form
            </h2>
          </div>

          <form onSubmit={handleSubmit} className='space-y-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Name
              </label>
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleInputChange}
                className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm '
                required
                disabled={userData?.registration_id}
              />
            </div>

            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Contact Number
              </label>
              <input
                type='tel'
                name='mobilenumber'
                value={formData.mobilenumber}
                onChange={handleInputChange}
                className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm '
                required
                disabled={userData?.registration_id}
              />
            </div>

            <button
              type='submit'
              className='mt-4 w-full bg-cmsgreen text-white py-2 rounded-md hover:bg-hovergreen transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2'
            >
              Submit
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default SimpleEnquiryForm;
