import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import { useSelector } from "react-redux";

const ProgramCourses = () => {
  const { title } = useParams(); // Extract the title from the route params
  const [courses, setCourses] = useState([]); // Store matched courses
  const [programTitle, setProgramTitle] = useState(""); // Store program title
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        // Decode the title (replace hyphens back to spaces)
        const decodedTitle = title.replace(/-/g, " ");

        // Fetch the data from the /branch API
        const response = await axios.get(`${Base_URL}/branch`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("Full API Response:", response.data);
        const data = response.data || [];

        // Match the decoded title with `stream_course_title`
        const matchedCourses = data.filter(
          (course) => course.stream_course_title === decodedTitle
        );

        setCourses(matchedCourses);
        setProgramTitle(decodedTitle); // Set the program title
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (title) {
      fetchCourses();
    }
  }, [title, token]);

  const handleButtonClick = (course) => {
    const formattedTitle = course.title.replace(/\s+/g, "-"); // Replace spaces with hyphens
    navigate(`/career/branch/${formattedTitle}`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow p-6 mt-20 ">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
             Explore Branches in {programTitle || "Program"}
          </h2>
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3  mt-20 lg:grid-cols-4">
            {courses.length > 0 ? (
              courses.map((course) => (
                <div
                  key={course.id}
                  className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
                >
                  <div className="absolute bottom-0 w-full">
                    <button
                      onClick={() => handleButtonClick(course)} // Pass the correct course object
                      className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                    >
                      {course.title}
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-span-4 text-center">
                No courses found for this program.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramCourses;
