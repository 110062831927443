import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import { useSelector } from "react-redux";

const CareerBranchSubContent = () => {
  const { title } = useParams(); // Fetch the dynamic part of the URL
  const [matchedContent, setMatchedContent] = useState(null);
  const [programTitle, setProgramTitle] = useState("Program");
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBranchContent = async () => {
      try {
        const decodedBranchTitle = title.replace(/-/g, " "); // Decode the branch title from the URL
        console.log("Decoded Branch Title:", decodedBranchTitle);

        // Fetch data dynamically based on the URL
        const response = await axios.get(
          `${Base_URL}/branch-sub-content/branch-content/${decodedBranchTitle}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("API Response:", response.data);
        const data = response.data;

        if (data && data.length > 0) {
          setMatchedContent(data[0]); // Assuming the first entry is relevant
          setProgramTitle(data[0].title); // Set the program title from the response
        } else {
          setMatchedContent(null);
          setProgramTitle("Program");
        }
      } catch (error) {
        console.error("Error fetching branch content:", error);
      }
    };

    if (title) {
      fetchBranchContent();
    }
  }, [title, token]);

  const handleNavigate = (contentTitle) => {
    const formattedTitle = contentTitle.trim().replace(/\s+/g, "-").toLowerCase();
    navigate(`/career/${formattedTitle}/details`);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow p-6 mt-20 ">
        <div className="container mx-auto">
          <h2 className="text-2xl font-bold mb-6 font-dm-sans mt-10 text-center">
           Explore Branch Sub Content for {programTitle}
          </h2>
          <div className="grid grid-cols-2 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-20">
            {matchedContent ? (
              <div
                key={matchedContent.id}
                className="border-[#75E36B] bg-white text-black shadow-md rounded-[10px] h-[60px] relative flex flex-col hover:bg-[#D9D9D9] hover:border-[#75E36B] hover:text-black transition-all duration-300"
              >
                <button
                  className="w-full h-[60px] bg-transparent text-black font-bold rounded-[10px] border border-[#75E36B] hover:bg-[#75E36B] hover:text-white transition-all ease-out duration-300 text-sm text-center break-words px-2"
                  onClick={() => handleNavigate(matchedContent.title)}
                >
                  {matchedContent.title}
                </button>
              </div>
            ) : (
              <p className="col-span-4 text-center">No content found for this program.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerBranchSubContent;
