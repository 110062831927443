import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Base_URL } from "../../apiConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const StudentProfile = () => {
  const token = useSelector((state) => state.auth.token);
  const [cities, setCities] = useState([]);
  const [marksType10th, setMarksType10th] = useState("");
  const [marksType12th, setMarksType12th] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isProfileExists, setIsProfileExists] = useState(false);
  const [initialFormData, setInitialFormData] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);
  
  const emptyFormData = {
    details_of_10th: {
      percentage: "",
      state: "",
      city: ""
    },
    details_of_12th: {
      percentage: "",
      state: "",
      city: ""
    },
    dob: "",
    gender: "",
    marital_status: "",
    physically_challenged: "",
    city_id: "",
    social_category: ""
  };

  const [formData, setFormData] = useState(emptyFormData);

  // Enhanced error handling toast function
  const handleApiError = (error) => {
    console.error("API Error:", error);
    
    if (error.response) {
      // The request was made and the server responded with a status code
      const errorMessage = error.response.data?.message || 
                           error.response.data?.error || 
                           "An unexpected error occurred";
      
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (error.request) {
      // The request was made but no response was received
      toast.error("No response received from server. Please check your network connection.", {
        position: "top-right",
        autoClose: 5000,
      });
    } else {
      // Something happened in setting up the request
      toast.error("Error setting up the request: " + error.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  // Fetch cities on component mount
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/city`);
        setCities(response.data || []);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };
    fetchCities();
  }, []);

  // Fetch profile data
  const fetchProfileData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${Base_URL}/student/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const fetchedData = response.data[0];
      
      if (fetchedData) {
        setIsProfileExists(true);
        
        setMarksType10th(fetchedData.details[0]["10th"] ? "percentage" : "cgpa");
        setMarksType12th(fetchedData.details[1]["12th"] ? "percentage" : "cgpa");

        const formattedData = {
          dob: fetchedData.dob ? fetchedData.dob.split("T")[0] : "",
          gender: fetchedData.gender || "",
          marital_status: fetchedData.marital_status || "",
          physically_challenged: fetchedData.physically_challenged || "",
          city_id: fetchedData.city_id || "",
          social_category: fetchedData.social_category || "",
          details_of_10th: {
            percentage: fetchedData.details[0]["10th"] || "",
            state: fetchedData.details[0].state || "",
            city: fetchedData.details[0].city || "",
          },
          details_of_12th: {
            percentage: fetchedData.details[1]["12th"] || "",
            state: fetchedData.details[1].state || "",
            city: fetchedData.details[1].city || "",
          },
        };

        setFormData(formattedData);
        setInitialFormData(formattedData);
        setIsEditing(false);
        setHasChanges(false);
      } else {
        // If no data exists, set empty form data
        setFormData(emptyFormData);
        setInitialFormData(emptyFormData);
        setIsProfileExists(false);
      }
    } catch (error) {
      if (error.response?.status === 404) {
        // Handle case when no profile exists
        setFormData(emptyFormData);
        setInitialFormData(emptyFormData);
        setIsProfileExists(false);
      } else {
        console.error("Error fetching profile:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Fetch profile data on component mount
  useEffect(() => {
    if (token) {
      fetchProfileData();
    }
  }, [token]);

  // Check for changes in form data
  useEffect(() => {
    if (initialFormData && isEditing) {
      const hasFormChanged = JSON.stringify(formData) !== JSON.stringify(initialFormData);
      setHasChanges(hasFormChanged);
    }
  }, [formData, initialFormData, isEditing]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    let updatedFormData = { ...formData };
    
    if (name.startsWith('10th_')) {
      const field = name.replace('10th_', '');
      updatedFormData.details_of_10th = {
        ...updatedFormData.details_of_10th,
        [field]: value
      };
    } else if (name.startsWith('12th_')) {
      const field = name.replace('12th_', '');
      updatedFormData.details_of_12th = {
        ...updatedFormData.details_of_12th,
        [field]: value
      };
    } else if (name === "city_id") {
      updatedFormData[name] = value ? parseInt(value, 10) : "";
    } else {
      updatedFormData[name] = value;
    }
    
    setFormData(updatedFormData);
    
    // Check if the form has actually changed
    const isChanged = JSON.stringify(updatedFormData) !== JSON.stringify(initialFormData);
    setHasChanges(isChanged);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!hasChanges) {
      toast.info("No changes to update", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }
    
    setIsSubmitting(true);
    try {
      const formattedData = {
        dob: formData.dob,
        gender: formData.gender,
        marital_status: formData.marital_status,
        physically_challenged: formData.physically_challenged,
        city_id: formData.city_id,
        social_category: formData.social_category,
        details: [
          {
            "10th": formData.details_of_10th.percentage,
            "state": formData.details_of_10th.state,
            "city": formData.details_of_10th.city
          },
          {
            "12th": formData.details_of_12th.percentage,
            "state": formData.details_of_12th.state,
            "city": formData.details_of_12th.city
          }
        ]
      };

      let response;
      if (isProfileExists) {
        // PUT request for existing profile
        response = await axios.put(`${Base_URL}/student/profile`, formattedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        // POST request for new profile
        response = await axios.post(`${Base_URL}/student/profile`, formattedData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      }

      if (response.data) {
        toast.success(isProfileExists ? "Profile updated successfully" : "Profile created successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        await fetchProfileData(); // Refresh data after update/create
        setIsEditing(false);
        setHasChanges(false);
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const toggleEdit = () => {
    if (isEditing && hasChanges) {
      // Ask for confirmation before canceling changes
      if (window.confirm("You have unsaved changes. Are you sure you want to cancel?")) {
        setFormData(initialFormData); // Reset to initial data
        setIsEditing(false);
        setHasChanges(false);
      }
    } else {
      setIsEditing(!isEditing);
      if (!isEditing) {
        setHasChanges(false);
      }
    }
  };

  return (
    <div className="flex flex-col items-center w-full px-4 md:px-6 lg:px-8 py-4 sm:py-8">
      {loading ? (
        <div className="flex items-center justify-center min-h-[400px]">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-cmsgreen"></div>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="w-full max-w-[1056px] mx-auto">
          {/* Basic Details Section */}
          <div className="w-full rounded-lg border border-solid border-[#00000040] bg-white shadow-md p-4 sm:p-6 mb-4 sm:mb-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="font-semibold text-[22px] text-black">
                Basic Details
              </h2>
              {isProfileExists && (
                <button 
                  type="button" 
                  onClick={toggleEdit}
                  className="hover:opacity-80 transition-opacity"
                >
                  {isEditing ? (
                    // Cancel icon (X)
                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 6L6 18M6 6L18 18" stroke="#FF4444" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  ) : (
                    // Edit icon
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2658_18942)">
                        <path
                          d="M3.75 21.8252V25.6252C3.75 25.9752 4.025 26.2502 4.375 26.2502H8.175C8.3375 26.2502 8.5 26.1877 8.6125 26.0627L22.2625 12.4252L17.575 7.7377L3.9375 21.3752C3.8125 21.5002 3.75 21.6502 3.75 21.8252ZM25.8875 8.8002C26.375 8.3127 26.375 7.5252 25.8875 7.0377L22.9625 4.1127C22.475 3.6252 21.6875 3.6252 21.2 4.1127L18.9125 6.4002L23.6 11.0877L25.8875 8.8002Z"
                          fill="#41B923"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2658_18942">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </button>
              )}
            </div>

            {/* Form Rows */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
              {/* Date of Birth */}
              <div className="flex flex-col">
                <label className="font-medium text-sm text-black mb-2">Date of Birth*</label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                  className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none"
                  required
                />
              </div>

              {/* Social Category */}
              <div className="flex flex-col">
                <label className="font-medium text-sm text-black mb-2">Social Category*</label>
                <select 
                  name="social_category"
                  value={formData.social_category}
                  onChange={handleChange}
                  className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                  required
                >
                  <option value="">Select</option>
                  <option value="GENERAL">General</option>
                  <option value="OBC">OBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                </select>
              </div>

              {/* Marital Status */}
              <div className="flex flex-col">
                <label className="font-medium text-sm text-black mb-2">Marital Status*</label>
                <select 
                  name="marital_status"
                  value={formData.marital_status}
                  onChange={handleChange}
                  className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                  required
                >
                  <option value="">Select</option>
                  <option value="Unmarried">Unmarried</option>
                  <option value="Married">Married</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* City */}
              <div className="flex flex-col">
                <label className="font-medium text-sm text-black mb-2">City*</label>
                <select 
                  name="city_id"
                  value={formData.city_id}
                  onChange={handleChange}
                  className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                  required
                >
                  <option value="">Select</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Are you Physically Challenged? */}
              <div className="flex flex-col">
                <label className="font-medium text-sm text-black mb-2">Are you Physically Challenged?*</label>
                <select 
                  name="physically_challenged"
                  value={formData.physically_challenged}
                  onChange={handleChange}
                  className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                  required
                >
                  <option value="">Select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </div>

              {/* Gender */}
              <div className="flex flex-col">
                <label className="font-medium text-sm text-black mb-2">Gender*</label>
                <select 
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                  required
                >
                  <option value="">Select</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>
            </div>
          </div>

          {/* Education Details */}
          <div className="w-full h-auto rounded-t-lg border border-solid border-[#00000040] bg-white shadow-md p-4 sm:p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="font-semibold text-[22px] leading-[28.64px]">
                Education Details
              </h2>
              <button>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2658_18942)">
                    <path
                      d="M3.75 21.8252V25.6252C3.75 25.9752 4.025 26.2502 4.375 26.2502H8.175C8.3375 26.2502 8.5 26.1877 8.6125 26.0627L22.2625 12.4252L17.575 7.7377L3.9375 21.3752C3.8125 21.5002 3.75 21.6502 3.75 21.8252ZM25.8875 8.8002C26.375 8.3127 26.375 7.5252 25.8875 7.0377L22.9625 4.1127C22.475 3.6252 21.6875 3.6252 21.2 4.1127L18.9125 6.4002L23.6 11.0877L25.8875 8.8002Z"
                      fill="#41B923"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2658_18942">
                      <rect width="30" height="30" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>

            {/* Class X Section */}
            <div className="mb-8">
              <h3 className="font-semibold text-lg mb-4 text-[#878787]">Class X*</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">Marks Type*</label>
                  <select 
                    className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                    value={marksType10th}
                    onChange={(e) => setMarksType10th(e.target.value)}
                    required
                  >
                    <option value="">Select your Mark Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="cgpa">CGPA</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">Percentage / GPA*</label>
                  <input
                    type="text"
                    name="10th_percentage"
                    value={formData.details_of_10th.percentage || ""}
                    onChange={handleChange}
                    className={`w-full h-[40px] rounded-lg px-3 focus:outline-none ${marksType10th ? 'border-cmsgreen' : 'border-gray-300 bg-gray-50 cursor-not-allowed'}`}
                    placeholder="Enter Percentage / GPA"
                    disabled={!marksType10th}
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">State*</label>
                  <input
                    type="text"
                    name="10th_state"
                    value={formData.details_of_10th.state}
                    onChange={handleChange}
                    className="w-full h-[40px] rounded-lg border border-cmsgreen px-3"
                    placeholder="Enter State"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">City*</label>
                  <input
                    type="text"
                    name="10th_city"
                    value={formData.details_of_10th.city}
                    onChange={handleChange}
                    className="w-full h-[40px] rounded-lg border border-cmsgreen px-3"
                    placeholder="Enter City"
                    required
                  />
                </div>
              </div>
            </div>

            {/* Class XII Section */}
            <div>
              <h3 className="font-semibold text-lg mb-4 text-[#878787]">Class XII*</h3>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">Marks Type*</label>
                  <select 
                    className="w-full h-[40px] rounded-lg border border-cmsgreen px-3 focus:outline-none appearance-none bg-white hover:bg-green-50 cursor-pointer"
                    value={marksType12th}
                    onChange={(e) => setMarksType12th(e.target.value)}
                    required
                  >
                    <option value="">Select your Mark Type</option>
                    <option value="percentage">Percentage</option>
                    <option value="cgpa">CGPA</option>
                  </select>
                </div>
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">Percentage / GPA*</label>
                  <input
                    type="text"
                    name="12th_percentage"
                    value={formData.details_of_12th.percentage}
                    onChange={handleChange}
                    className={`w-full h-[40px] rounded-lg px-3 focus:outline-none ${marksType12th ? 'border-cmsgreen' : 'border-gray-300 bg-gray-50 cursor-not-allowed'}`}
                    placeholder="Enter Percentage / GPA"
                    disabled={!marksType12th}
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">State*</label>
                  <input
                    type="text"
                    name="12th_state"
                    value={formData.details_of_12th.state}
                    onChange={handleChange}
                    className="w-full h-[40px] rounded-lg border border-cmsgreen px-3"
                    placeholder="Enter State"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="font-medium text-sm mb-2">City*</label>
                  <input
                    type="text"
                    name="12th_city"
                    value={formData.details_of_12th.city}
                    onChange={handleChange}
                    className="w-full h-[40px] rounded-lg border border-cmsgreen px-3"
                    placeholder="Enter City"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          {/* <div className="flex justify-center sm:justify-end mt-4">
            <button
              type="submit"
              className="w-full sm:w-auto bg-cmsgreen text-white px-6 py-2 rounded-lg hover:bg-green-600"
            >
              Save Changes
            </button>
          </div> */}
          <div className="flex justify-center sm:justify-end mt-4">
          <div className="flex justify-center sm:justify-end mt-4">
  <button
    type="submit"
    className={`w-full sm:w-auto px-6 py-2 rounded-lg flex items-center justify-center transition-all
      bg-cmsgreen hover:bg-green-600 text-white`}
  >
    {isSubmitting ? (
      <div className="flex items-center space-x-2">
        <div className="w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></div>
        <span>{hasChanges? "Updating..." : "Submitting..."}</span>
      </div>
    ) : (
      hasChanges ? "Update" : "Submit"
    )}
  </button>
</div>


          </div>
          <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
        </form>
      )}
    </div>
  );
};
export default StudentProfile;