import React from 'react';
import { Parser } from 'htmlparser2';

const CareerPros = ({ content }) => {
  const parseHtml = (html) => {
    const result = [];
    let currentElement = null;
    const elementStack = [];

    const parser = new Parser(
      {
        onopentag(name, attributes) {
          const newElement = { name, attributes, children: [] };
          if (currentElement) {
            currentElement.children.push(newElement);
            elementStack.push(currentElement);
          } else {
            result.push(newElement);
          }
          currentElement = newElement;
        },
        ontext(text) {
          if (currentElement) {
            currentElement.children.push(text);
          } else {
            result.push(text);
          }
        },
        onclosetag() {
          if (elementStack.length > 0) {
            currentElement = elementStack.pop();
          } else {
            currentElement = null;
          }
        },
      },
      { decodeEntities: true }
    );

    parser.write(html || '');
    parser.end();

    return result;
  };

  const renderContent = (content) => {
    return content.map((item, index) => {
      if (typeof item === 'string') {
        return item;
      }

      const Tag = item.name;

      // Apply specific styles for tags
      const props = {
        ...item.attributes,
        key: index,
        className:
          Tag === 'table'
            ? 'min-w-full border-collapse border border-gray-300 my-4 overflow-x-auto'
            : Tag === 'p'
            ? 'mb-4'
            : Tag === 'a'
           ? 'text-blue-600 hover:underline'
           : '',
       };

      if (Tag === 'tr') {
        props.className = index % 2 === 0 ? 'bg-gray-100' : 'bg-white';
      }

      if (Tag === 'td' || Tag === 'th') {
        props.className = 'border border-gray-300 px-4 py-2';
      }

      // Handle void elements (self-closing tags)
      const isVoidElement = ['br', 'img', 'hr', 'input', 'meta', 'link'].includes(Tag);
      if (isVoidElement) {
        return React.createElement(Tag, props);
      }

      return React.createElement(
        Tag,
        props,
        item.children && renderContent(item.children)
      );
    });
  };

  const parsedContent = content ? parseHtml(content) : [];

  return (
    <div className="py-8 px-5">
      <h2 className="text-xl font-bold mb-4">Pros</h2>
      <div className="prose max-w-none">
        {content ? renderContent(parsedContent) : <p>No data available.</p>}
      </div>
    </div>
  );
};

export default CareerPros;
