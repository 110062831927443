import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux'; // Import useSelector to access the Redux store

const AddUniCourses = () => {
  const navigate = useNavigate();
  const [programs, setPrograms] = useState([]);
  const [formData, setFormData] = useState({
    course_master_name: '',
    created_by: 1,
    program_id: null
  });
  const { token } = useSelector((state) => state.auth); // Access token from Redux store

  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get-values-array/program_master`);
        const programOptions = response.data.map((program) => ({
          value: program[0],
          label: program[1],
        }));
        setPrograms(programOptions);
      } catch (error) {
        toast.error('Failed to fetch programs');
        console.error('Failed to fetch programs:', error);
      }
    };

    fetchPrograms();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart()
    }));
  };

  const handleProgramChange = (selectedOption) => {
    setFormData({ ...formData, program_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
    // Trim all string fields in formData
    const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
      acc[key] = typeof formData[key] === 'string' ? formData[key].trim() : formData[key];
      return acc;
  }, {});

  const newCourse = { ...trimmedFormData };
      await axios.post(`${Base_URL}/create/course_master`, newCourse, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}` // Add authorization header with token
        },
      });
      toast.success('Course added successfully!');
    } catch (error) {
      toast.error('Failed to add course.');
      console.error('Failed to add course:', error.response?.data || error.message);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />

        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="course_master_name" className="mb-2 font-medium text-sm">Add Course</label>
                <input
                  type="text"
                  id="course_master_name"
                  name="course_master_name"
                  value={formData.course_master_name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label className="mb-2 font-medium text-sm">Select Program</label>
                <Select
                  options={programs}
                  value={programs.find(program => program.value === formData.program_id)}
                  onChange={handleProgramChange}
                  placeholder="Select Program"
                  className="basic-single"
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">Add Course</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddUniCourses;
