import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { Base_URL } from '../../../apiConfig';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderTitle from '../../dashboard/HeaderTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

const AddState = () => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth); // Retrieve token from Redux store
  const [formData, setFormData] = useState({
    name: '',
    country_id: ''
  });
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { mainTitle, subTitle } = location.state || {};

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(`${Base_URL}/get/country`);
        const countryOptions = response.data.map((country) => ({
          value: country.id,
          label: country.name,
        }));
        setCountries(countryOptions);
      } catch (error) {
        console.error('Failed to fetch countries:', error);
        toast.error('Failed to load country options.');
      }
    };
    fetchCountry();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value.trimStart()
    }));
  };

  const handleCountryChange = (selectedOption) => {
    setFormData({ ...formData, country_id: selectedOption.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
       // Trim all string fields in formData
       const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
        acc[key] = typeof formData[key] === 'string' ? formData[key].trim() : formData[key];
        return acc;
    }, {});

    const newState = { ...trimmedFormData };
      await axios.post(`${Base_URL}/create/state`, newState, {
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}` // Add token to authorization header
        },
        withCredentials: true // Enable withCredentials
      });
      toast.success('State added successfully!');
    } catch (error) {
      toast.error('Failed to add state.');
      console.error('Failed to add state:', error.response?.data || error.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="bg-gray-100 p-6 h-screen">
        <HeaderTitle mainTitle={mainTitle} subTitle={subTitle} />
        <div className="bg-white p-4 rounded-md">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex flex-col">
                <label htmlFor="name" className="mb-2 font-medium text-sm">Create State</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="border border-gray-300 p-2 rounded"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="country_id" className="mb-2 font-medium text-sm">Select Country</label>
                <Select
                  options={countries}
                  onChange={handleCountryChange}
                  placeholder="Select Country"
                  className="basic-single"
                />
              </div>
            </div>

            <button type="submit" className="mt-6 px-4 py-2 bg-cmsgreen text-white rounded text-sm hover:bg-hovergreen">
              {loading ? (
                <div className="flex justify-center items-center">
                  <svg
                    className="animate-spin h-5 w-5 text-white mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                    ></path>
                  </svg>
                  Submitting...
                </div>
              ) : (
                'Add State'
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddState;
