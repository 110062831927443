import React from 'react';
import { useNavigate } from "react-router-dom";
const MarketingPartner = () => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate("/become-a-partner/register");
    };
    const cardData = [
        {
          title: "Flexible Work Opportunities",
          description:
            "No fixed hours - work at your own pace",
          bgColor: "#EAFFD6",
        },
        {
          title: "Earn as you Grow",
          description:
            " Get Rs 6000 for every student you add to our platform",
          bgColor: "#FFF5E6",
        },
        {
          title: "Boost your Resume",
          description:
            "Receive an official certificate and a professional offer letter.",
          bgColor: "#E6F7FF",
        },
      ];
      
  return (
    <div className="w-full bg-white font-[sans-serif] py-12 px-6 lg:px-20">
      {/* Common Wrapper for Consistent Margins */}
      <div className="max-w-[1200px] mx-auto px-4">

        {/* Top Center Text */}
        <div className="mb-8 mt-8">
        <h1 className="text-[28px] sm:text-[30px] lg:text-[40px] text-center font-bold leading-[1.2] lg:leading-[83.33px] text-textGray underline decoration-transparent px-4">
  Become Our Certified Marketing Partner
</h1>

          <p className="text-[24px] text-center text-[#404040] font-medium mt-4">
          Unlock Opportunities,Make a Difference and Earn Rewards!
          </p>
        </div>

        {/* Section Text Below Heading with Center Alignment */}
        <div className="text-left mt-8 lg:mt-12">
          <p className="text-[24px] font-bold leading-[31.25px] text-black mb-2">
           Are you looking for a chance to kickstart your career while making a meaningful impact?
          </p>
          <p className="text-[20px] font-medium leading-[26.04px] text-black mb-6">
          Join us as a Certified Marketing Partner and become a crucial part of connecting students with the opportunities they deserve.
          </p>
          <p className="text-[28px] mt-12 font-[700] leading-[31.25px] mb-2 text-black">
           Why Join Us?
          </p>
        </div>

        {/* Three Cards Section - Responsive & Full Screen Width */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8 px-0 lg:px-0">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="relative w-full h-[200px] rounded-tl-[10px] shadow-lg flex flex-col items-center justify-start px-4 py-6"
              style={{ backgroundColor: card.bgColor }}
            >
              {/* Card Top Center Text */}
              <h2 className="font-bold text-[20px] leading-[26.04px] mb-2 text-center min-h-[52px]">
                {card.title}
              </h2>
              {/* Card Bottom Center Text */}
              <p className="text-[18px] font-medium leading-[23.44px] opacity-90 text-center mt-2">
                {card.description}
              </p>
            </div>
          ))}
        </div>
        {/* Center Lines Section with Center Alignment */}
        <div className="mt-12 text-center lg:text-left">
        <p className="text-[28px] font-[700] leading-[31.25px] mb-2 text-black">
           Become a Bridge to Opportunity and Success
          </p>
          <p className="text-[20px] mt-8 font-[500] leading-[31.25px] mb-2 text-black">
           Spread the word about our platform to prospective students.
          </p>
          <p className="text-[20px] font-[500] leading-[31.25px] mb-2 text-black">
            Connect with students in your area and assist them in joining our portal.
          </p>
          <p className="text-[20px] font-[500] leading-[31.25px]  text-black">
            Act as a bridge between students and their dream colleges.
          </p>
        </div>

        {/* Call-to-Action Section */}
        <div className="mt-8 flex justify-center">
          <button className="bg-[#3ACA2E] hover:bg-[#349A2E] text-white px-6 py-2 rounded-[10px] text-md font-medium leading-[31.25px] focus:outline-none"
          onClick={handleClick}>
            Become a Partner
          </button>
        </div>
        
      </div>
    </div>
  );
};

export default MarketingPartner;
