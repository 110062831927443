import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {ReactComponent as Facebook} from "../assets/socialicons/facebook.svg"
import {ReactComponent as LinkedIn} from "../assets/socialicons/linkedin.svg"
import {ReactComponent as Twitter} from "../assets/socialicons/twitter.svg"
import {ReactComponent as Instagram} from "../assets/socialicons/insta.svg"

const SocialMediaIcons = () => {
    const location = useLocation();
    const [showIcons, setShowIcons] = useState(false);
  
  // Exclude these routes
  const excludedRoutes = ["/dashboard", "/admin"];
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowIcons(true);
      } else {
        setShowIcons(false);
      }
    };

    // Add event listener on scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (excludedRoutes.includes(location.pathname)) {
    return null; // Don't render on excluded routes
  }
 

  return (
    <>
    {showIcons && (
    <div className="fixed left-2 top-1/2 transform -translate-y-1/2 flex flex-col gap-3 z-[999]">
      <a
        href="https://www.facebook.com/profile.php?id=61566075511198&mibextid=ZbWKwL"
        target="_blank"
        rel="noopener noreferrer"
        className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-700 shadow-md hover:bg-blue-600 hover:text-white transition"
      >
      <Facebook/>
      </a>
      <a
        href="https://x.com/Collegedwarka"
        target="_blank"
        rel="noopener noreferrer"
        className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-700 shadow-md hover:bg-black hover:text-white transition"
      >
      <Twitter/>
      </a>
      <a
        href="https://www.instagram.com/collegedwarka?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
        target="_blank"
        rel="noopener noreferrer"
        className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-700 shadow-md hover:bg-pink-500 hover:text-white transition"
      >
      <Instagram/>
      </a>
      <a
        href="https://www.linkedin.com/company/collegedwarka"
        target="_blank"
        rel="noopener noreferrer"
        className="w-10 h-10 flex items-center justify-center bg-gray-200 rounded-full text-gray-700 shadow-md hover:bg-blue-700 hover:text-white transition"
      >
     <LinkedIn/>
      </a>
    </div>
    )}
    </>
  );
};

export default SocialMediaIcons;
