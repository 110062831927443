import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

import CareerEligibility from './CareerEligibility';
import CareerJobRoles from './CareerJobRoles';
import CareerWorkDescription from './CareerWorkDescription';
import CareerSalary from './CareerSalary';
import CareerPrepBook from './CareerPrepBook';
import { Base_URL } from '../apiConfig';
import CareerAbout from './CareerAbout';
import CareerPathVideo from './CareerPathVideo';
import CareerPros from './CareerPros';
import CareerCons from './CareerCons';

const api = axios.create({
  baseURL: Base_URL,
  timeout: 10000,
});

const CareerLayout = () => {
  const { career } = useParams();
  const [content, setContent] = useState({
    title: '',
    about: '',
    eligibility: '',
    jobRoles: '',
    workDescription: '',
    salary: '',
    prepBooks: '',
    pros: '',
    cons: '',
    path: [], // Make sure path is an array
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSection, setSelectedSection] = useState('About');
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchContent = async () => {
      setLoading(true);
      setError(null);
  
      try {
        const endpoints = [
          'title',
          'about',
          'eligibility',
          'job_role',
          'work_description',
          'salary',
          'prep_books',
          'pros',
          'cons',
          'path',
        ];
  
        const formattedName = career.replace(/-/g, ' ');
        const requests = endpoints.map((endpoint) =>
          api.get(`${Base_URL}/branch-sub-content/detail/${formattedName}/${endpoint}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => response)
          .catch((error) => {
            console.error(`Error fetching ${endpoint}:`, error);
            return { data: [{ [endpoint]: '' }] };
          })
        );
  
        const responses = await Promise.all(requests);
  
        setContent({
          title: responses[0].data[0]?.title || '',
          about: responses[1].data[0]?.about || '',
          eligibility: responses[2].data[0]?.eligibility || '',
          jobRoles: responses[3].data[0]?.job_role || '',
          workDescription: responses[4].data[0]?.work_description || '',
          salary: responses[5].data[0]?.salary || '',
          prepBooks: responses[6].data[0]?.prep_books || '',
          pros: responses[7].data[0]?.pros || '',
          cons: responses[8].data[0]?.cons || '',
          path: responses[9].data || [],
        });
      } catch (error) {
        console.error('Error fetching content:', error);
        setError('Failed to load career information. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
  
    if (career) fetchContent();
  }, [career, token]);

  const sections = [
    'About',
    'Eligibility',
    'Job Roles',
    'Work Description',
    'Salary',
    'Prep Books',
    'Pros',
    'Cons',
    'Path',
  ];

  const sectionComponents = {
    About: <CareerAbout content={content.about} />,
    Eligibility: <CareerEligibility content={content.eligibility} />,
    'Job Roles': <CareerJobRoles content={content.jobRoles} />,
    'Work Description': <CareerWorkDescription content={content.workDescription} />,
    Salary: <CareerSalary content={content.salary} />,
    'Prep Books': <CareerPrepBook content={content.prepBooks} />,
    Pros: <CareerPros content={content.pros} />,
    Cons: <CareerCons content={content.cons} />,
    Path: <CareerPathVideo content={content.path} />, // Pass path data here
  };

  const renderSection = () => sectionComponents[selectedSection] || null;

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center p-8 bg-red-50 rounded-lg">
          <p className="text-red-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
    <h1 className="text-center text-2xl md:text-2xl font-bold mt-[70px] mb-12 px-4">
      {content.title}
    </h1>

    {/* Section Navigation */}
    <div
      className="flex gap-5 p-4 text-sm sm:text-base font-medium text-black bg-white shadow-md md:justify-center rounded"
      style={{
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        scrollbarWidth: 'none', 
        msOverflowStyle: 'none', 
      }}
      
      onScroll={(e) => e.target.style.scrollbarWidth = 'none'}
    >
      {sections.map((section) => (
        <button
          key={section}
          className={`py-2 px-4 transition-colors ${
            selectedSection === section
              ? 'text-[#41B923] border-b-2 border-[#41B923]'
              : 'hover:text-[#41B923]'
          }`}
          onClick={() => setSelectedSection(section)}
        >
          {section}
        </button>
      ))}
    </div>

    {/* Section Content */}
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
      {loading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-pulse space-y-4">
            <div className="h-4 bg-gray-200 rounded w-[250px]"></div>
            <div className="h-4 bg-gray-200 rounded w-[200px]"></div>
            <div className="h-4 bg-gray-200 rounded w-[150px]"></div>
          </div>
        </div>
      ) : (
        <div className="w-full overflow-hidden">{renderSection()}</div>
      )}
    </div>
  </div>
  );
};

export default CareerLayout;
