import React, { useState } from "react";
import CareerSearch from "./CareerSearch";
import CareersGrid from "./CareersGrid";
import CareerTop from "./CareerTop";

function CareerPage() {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className="w-full">
      <div className=" w-full">
        {/* <CareerTop /> */}
        <CareerSearch onSearchChange={setSearchQuery} />
        <CareersGrid searchQuery={searchQuery} />
       
      </div>
    </div>
  );
}

export default CareerPage;
